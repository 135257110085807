import { GetRequireDemo } from '../typings/request';
import { get } from '../../infra/rest';

let cachePromise: Promise<GetRequireDemo>;
let cacheData: GetRequireDemo;

const getRequireDemo = async (refresh?: boolean) => {
  if (!refresh) {
    if (cacheData) {
      return cacheData;
    }

    if (cachePromise) {
      return cachePromise;
    }
  }

  cachePromise = get<GetRequireDemo>(`/v1/auth/require-demo`, {}, true).then(
    (data) => {
      cacheData = data;
      return data;
    }
  );

  return cachePromise;
};

export default getRequireDemo;
