/** @jsxImportSource @emotion/react */
import { Modal, ModalProps } from '@mui/material';
import { css } from '@emotion/react';

const QSModal = (props: ModalProps) => {
  return (
    <Modal
      css={css`
        outline: none;
        &:focus-visible {
          outline: none;
        }
      `}
      {...props}
    />
  );
};

export default QSModal;
