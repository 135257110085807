import { get } from '../../../../infra/rest';
import { GetSubscribedPlanResponse } from '../typings';

export const getSubscribedPlan = () => {
  return get<GetSubscribedPlanResponse>(
    `/v1/plan/subscription/status`,
    undefined,
    true
  );
};
