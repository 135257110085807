/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import ForDefaultPermission from './ForDefaultPermission';
import ForDeniedPermission from './ForDeniedPermission';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../../shared/constants/routes';
import { getMessagingToken } from '../../../../../infra/firebase/init';
import { sendSetupToken } from '../../../../../shared/requests/send-setup-token';

const PushNotificationPermissionPopup = () => {
  const [openDefaultPopup, setOpenDefaultPopup] = useState(false);
  const [defaultPopupClosed, setDefaultPopupClosed] = useState(false);
  const [openDeniedNotificationPopup, setOpenDeniedNotificationPopup] =
    useState(false);
  const navigate = useNavigate();
  const showingIntegrationPopup =
    localStorage.getItem('SHOW_POPUP') !== 'false' &&
    window.location.pathname === ROUTES.DASHBOARD;

  const setRegistration = async () => {
    const token = await getMessagingToken();
    if (token) {
      await sendSetupToken(token);
    }
  };

  useEffect(() => {
    const notificationPreference = localStorage.getItem(
      'pushNotificationDefaultDismissed'
    );
    if (
      !notificationPreference &&
      !showingIntegrationPopup &&
      'Notification' in window &&
      Notification.permission === 'default'
    ) {
      setOpenDefaultPopup(true);
    } else {
      setOpenDefaultPopup(false);
    }
  }, [showingIntegrationPopup]);

  useEffect(() => {
    const notificationDeniedPreference = localStorage.getItem(
      'pushNotificationDeniedDismissed'
    );
    const notificationPreference = localStorage.getItem(
      'pushNotificationDefaultDismissed'
    );
    if (
      !notificationDeniedPreference &&
      !showingIntegrationPopup &&
      'Notification' in window &&
      Notification.permission !== 'granted'
    ) {
      setOpenDeniedNotificationPopup(true);
    } else {
      setOpenDeniedNotificationPopup(false);
    }
  }, [showingIntegrationPopup]);

  const handleAllowNotifications = () => {
    handleCloseDefaultPopup();
    if ('Notification' in window) {
      if (Notification.permission === 'granted') {
        console.log('Notification permission already granted.');
      } else if (Notification.permission === 'denied') {
        console.log('Notification permission has been denied.');
      } else if (Notification.permission === 'default') {
        Notification.requestPermission()
          .then(async (permission) => {
            if (permission === 'granted') {
              setRegistration();
            }
          })
          .catch((error) => {
            console.error('Failed to request notification permission:', error);
          });
      }
    } else {
      console.log('This browser does not support desktop notification.');
    }
  };

  const handleOpenNotificationSettings = () => {
    navigate(`${ROUTES.SETTINGS}${ROUTES.PUSH_NOTIFICATIONS}`);
    setOpenDefaultPopup(false);
    setOpenDeniedNotificationPopup(false);
  };

  const handleCloseDefaultPopup = () => {
    localStorage.setItem('pushNotificationDefaultDismissed', 'true'); // Store preference
    setOpenDefaultPopup(false);
    setDefaultPopupClosed(true);
  };

  const handleCloseDeniedPopup = () => {
    localStorage.setItem('pushNotificationDeniedDismissed', 'true'); // Store preference
    setOpenDeniedNotificationPopup(false);
  };

  if (openDefaultPopup) {
    return (
      <ForDefaultPermission
        onClose={handleCloseDefaultPopup}
        handleOpen={handleAllowNotifications}
      />
    );
  }
  if (!defaultPopupClosed && openDeniedNotificationPopup) {
    return (
      <ForDeniedPermission
        onClose={handleCloseDeniedPopup}
        handleOpen={handleOpenNotificationSettings}
      />
    );
  }

  return null;
};

export default PushNotificationPermissionPopup;
