import { useEffect, useRef } from 'react';
import { getMessagingToken } from '../../infra/firebase/init';
import useNotifications from './use-notifications';
import { sendSetupToken } from '../requests/send-setup-token';

export const useRegisterDevice = ({
  isAuthReady,
  isOrgSelected,
}: {
  isAuthReady: boolean;
  isOrgSelected: boolean;
}) => {
  const { addNotification } = useNotifications();
  const listen = useRef(true);
  useEffect(() => {
    if (!listen.current || !isAuthReady || !isOrgSelected) return;
    const tokenRegister = async () => {
      if ('Notification' in window && Notification.permission === 'granted') {
        const token = await getMessagingToken();
        if (token) {
          await sendSetupToken(token);
        }
      }
    };
    const timer = setTimeout(tokenRegister, 3000);
    return () => {
      window.clearTimeout(timer);
    };
  }, [isAuthReady, isOrgSelected, addNotification]);
};
