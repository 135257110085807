import { FC, SVGProps } from 'react';

const TemplateAttachmentIcon: FC<
  SVGProps<SVGSVGElement> & { size?: number }
> = ({ size = 24, ...props }) => {
  return (
    <svg
      fill="#2d2d2d"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      {...props}
    >
      <path d="M 3 4 C 1.895 4 1 4.895 1 6 L 1 18 C 1 19.105 1.895 20 3 20 L 14 20 L 14 18 L 3 18 L 3 8.0019531 L 11 13 L 19 8.0019531 L 19 13 L 21 13 L 21 6 C 21 4.895 20.105 4 19 4 L 3 4 z M 3 6 L 19 6 L 19 6.0019531 L 11 11 L 3 6.0019531 L 3 6 z M 19 15 L 19 18 L 16 18 L 16 20 L 19 20 L 19 23 L 21 23 L 21 20 L 24 20 L 24 18 L 21 18 L 21 15 L 19 15 z" />
    </svg>
  );
};

export default TemplateAttachmentIcon;
