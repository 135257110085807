import { post } from '../../../../infra/rest';
import { UserAuth } from '../../../../shared/typings/request';

const chooseOrganisation = async (
  orgId: string,
  {
    options,
    authRequired = true,
  }: {
    options?: RequestInit;
    authRequired?: boolean;
  } = {}
) => {
  return post<UserAuth>(
    `/v3/auth/login`,
    { ...options, body: JSON.stringify({ orgId }) },
    authRequired
  );
};

export default chooseOrganisation;
