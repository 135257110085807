import {
  createPaginatedRequestAtom,
  createRequestFamily,
} from '../../../../shared/utils/request-atom';
import {
  CustomerChatMeta,
  InternalNote,
  InternalNotesListPaginationData,
  GetSingleSelectCustomField,
} from '../typings';
import { atom } from 'jotai';
import { chatListAtom } from './chat-list';
import { chatRowSetter } from './chat';
import { selectedChatAtom, selectedChatIdAtom } from './selected-chat';
import { CHAT_UPDATE_TYPE } from '../typings/chat';
import { ChatType } from '../components/chat-list-section/chat-row/typings';

export const customerDetailAtomFamily = createRequestFamily<
  CustomerChatMeta | undefined
>(undefined);

export const internalNotesListAtom = createPaginatedRequestAtom<
  InternalNote,
  InternalNotesListPaginationData
>({ loading: false });

export const listFieldRequestFamily = createRequestFamily<
  GetSingleSelectCustomField | undefined
>({
  initialLoading: false,
});

export const fetchedCacheForListAtom = atom(false);

export const listFieldSetter = atom(
  (get) => get(fetchedCacheForListAtom),
  (get, set, data: Array<GetSingleSelectCustomField>) => {
    if (!data.length) {
      return;
    }

    set(fetchedCacheForListAtom, true);
    data.forEach((item) => {
      set(listFieldRequestFamily({ id: item.id }), {
        loading: false,
        data: item,
        fetched: true,
      });
    });
  }
);

export const listFieldChatSetter = atom(
  null,
  (
    get,
    set,
    data: {
      listId: string;
      oldValue?: string | number;
      value: string | null;
    }
  ) => {
    //
    const filters = get(chatListAtom);
    const selectedChat = get(selectedChatAtom);

    if (!selectedChat) return;

    const customListFilters = filters.customList;
    const currentSelectedListId = filters.currentCustomListId;

    const currentFilteredFieldId = customListFilters[currentSelectedListId];

    if (data.listId === currentSelectedListId && currentFilteredFieldId) {
      if (currentFilteredFieldId === data.value) {
        set(chatRowSetter, {
          type: CHAT_UPDATE_TYPE.ADD,
          data: selectedChat,
          reOrder: true,
        });
      } else {
        set(chatRowSetter, {
          type: CHAT_UPDATE_TYPE.DELETE,
          id: selectedChat.id,
        });
      }
    }

    const listAtom = listFieldRequestFamily({ id: data.listId });
    const listData = get(listAtom);

    set(listAtom, {
      ...listData,
      ...(listData.data
        ? {
            data: {
              ...listData.data,
              list: [
                ...listData.data.list.map((item) => {
                  if (item.id === data.oldValue) {
                    return {
                      ...item,
                      customerCount:
                        item.customerCount >= 1
                          ? item.customerCount - 1
                          : item.customerCount,
                    };
                  }

                  if (item.id === data.value) {
                    return {
                      ...item,
                      customerCount: item.customerCount + 1,
                    };
                  }

                  return item;
                }),
              ],
            },
          }
        : {}),
    });
  }
);
