import { ROUTES } from '../constants/routes';

// NavigationFragment            -> (Navigation drawer which contains , Teams , Home , Integrations etc)

//  MainActivity                 -> (Same as chat list , Chat list is part of this screen)
//  ChatListFragment             -> (1st chat screen in which all chats are showing)
//  ContactSelectionActivity     -> (Click on new chat then this screen with contact list appears)
//  CustomerDetailActivity       -> (Opening customer details -> From individual chat click on customer name -> This screen appears)
//  FilterChatDialogFragment     -> (On 1st chat screen then click on filter -> For applying filter and tags)
//  IndividualChatActivity       -> (1-1 conversation messages screen)
//  TagsDialog                   -> (Popup with all tags and create tag option)

// SettingsFragment              -> (Settings screen)

// LoginFragment                 -> (Screen where user enter his phone)
// VerifyOtpFragment             -> (OTP screen with verify button)
// RequestDemoFragment           -> (Request demo screen)
// SelectOrganizationFragment    -> (Organization list screen)

// CommonWebViewActivity         -> (Opening CRM-Mini page)

// CreateOrganizationFragment    -> (Organization create screen)

// GroupMemberSelectionActivity  -> (Broadcasts list screen then click on any percntage)

// NotificationService           -> (When recivee new notification , Its not actuall screen)
//// SafeCollector               -> (Api call response thread , Its not actual screen)
//// ScopeCoroutine              -> (Same)
// StandaloneCoroutine           -> (Not actuall screen , Api call response)

export enum Screens {
  // sidebar screen name, correct or as per url which is open?
  NavigationFragment = 'NavigationFragment',

  // home screen
  MainActivity = 'MainActivity',
  ChatListFragment = 'ChatListFragment',
  ContactSelectionActivity = 'ContactSelectionActivity',
  CustomerDetailActivity = 'CustomerDetailActivity',
  FilterChatDialogFragment = 'FilterChatDialogFragment',
  IndividualChatActivity = 'IndividualChatActivity',
  TagsDialog = 'TagsDialog',

  // settings screen
  SettingsFragment = 'SettingsFragment',

  // login -> phone state
  LoginFragment = 'LoginFragment',

  // login -> otp-screen
  VerifyOtpFragment = 'VerifyOtpFragment',

  // login -> request demo screen
  RequestDemoFragment = 'RequestDemoFragment',

  // login -> org-screen
  SelectOrganizationFragment = 'SelectOrganizationFragment',

  // crm-mini pages screen-name
  CommonWebViewActivity = 'CommonWebViewActivity',

  // create org screen[i guess the modal]
  CreateOrganizationFragment = 'CreateOrganizationFragment',
}

export enum NextScreenState {
  GET_STARTED_DEMO = 'GET_STARTED_DEMO',
  NEW_INTEGRATION = 'NEW_INTEGRATION',
  SELECT_ORGANIZATION = 'SELECT_ORGANIZATION',
  PAYMENT_BLOCKER = 'PAYMENT_BLOCKER',
  EMAIL_VERIFICATION = 'EMAIL_VERIFICATION',
  MAIN_SCREEN = 'MAIN_SCREEN',
  BILLING_DETAILS = 'BILLING_DETAILS',
  COMPANY_DETAILS = 'COMPANY_DETAILS',
}

export const nextScreenStateToRouteMap: { [key: string]: string } = {
  [NextScreenState.BILLING_DETAILS]: ROUTES.BILLING_DETAILS,
  [NextScreenState.COMPANY_DETAILS]: ROUTES.COMPANY_DETAILS,
  [NextScreenState.NEW_INTEGRATION]: ROUTES.NEXT_STEPS,
  [NextScreenState.MAIN_SCREEN]: ROUTES.DASHBOARD.replace('/*', '/'),
  [NextScreenState.SELECT_ORGANIZATION]: ROUTES.LOGIN,
  [NextScreenState.PAYMENT_BLOCKER]: ROUTES.LOGIN,
  [NextScreenState.GET_STARTED_DEMO]: ROUTES.LOGIN,
};

export {};
