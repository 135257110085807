import { get } from '../../../infra/rest';
import { GetEnterpriseAnalyticsEmbedLinkResponse } from '../typings';

const getEnterpriseAnalyticsEmbedLink = async (): Promise<
  GetEnterpriseAnalyticsEmbedLinkResponse | undefined
> => {
  return get<GetEnterpriseAnalyticsEmbedLinkResponse>(
    `/v1/enterprise-analytics/embed-link`,
    {},
    true
  );
};

export default getEnterpriseAnalyticsEmbedLink;
