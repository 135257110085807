import { useAtom } from 'jotai';
import { organizationAtom, selectedOrganizationAtom } from '../states/login';
import { rootScope } from '../states/user';
import { useCallback } from 'react';
import { getOrganizations } from '../requests/organizations';

export function useOrganizationsAtom() {
  return useAtom(organizationAtom, rootScope);
}

export function useOrganizations() {
  const [organizationState, setOrganizationState] = useOrganizationsAtom();

  const fetchOrganizations = useCallback(
    async (refresh: boolean = false) => {
      if (!refresh && organizationState?.organisations) {
        return organizationState.organisations;
      }

      try {
        setOrganizationState((prevState) => {
          if (!prevState) {
            return {
              organisations: [],
              chooseOrganization: false,
              selectOrg: '',
              firstTimeSetup: false,
              loading: true,
            };
          }
          return { ...prevState, loading: true };
        });

        const organizations = await getOrganizations();
        const size = organizations.length;

        setOrganizationState(() => ({
          organisations: organizations,
          chooseOrganization: size > 0,
          selectOrg: '',
          firstTimeSetup: !size,
          loading: false,
        }));

        return organizations;
      } catch (error) {
        return [];
      }
    },
    [setOrganizationState]
  );

  return { organizationState, setOrganizationState, fetchOrganizations };
}

export const useSelectedOrganisationAtom = () =>
  useAtom(selectedOrganizationAtom, rootScope);

export const useSelectedOrganization = () => {
  return useSelectedOrganisationAtom()[0];
};

export const useSetSelectedOrganization = () => {
  return useSelectedOrganisationAtom()[1];
};
