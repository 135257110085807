import { FC, SVGProps } from 'react';

const HomeIcon: FC<SVGProps<SVGSVGElement> & { size?: number }> = ({
  color,
  size = 24,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="#2d2d2d"
      stroke={color}
      strokeWidth="0"
      // strokeLinecap="round"
      // strokeLinejoin="round"
      {...props}
    >
      <path
        stroke={color}
        d="M 12 2.0996094 L 1 12 L 4 12 L 4 21 L 11 21 L 11 15 L 13 15 L 13 21 L 20 21 L 20 12 L 23 12 L 12 2.0996094 z M 12 4.7910156 L 18 10.191406 L 18 11 L 18 19 L 15 19 L 15 13 L 9 13 L 9 19 L 6 19 L 6 10.191406 L 12 4.7910156 z"
      />
    </svg>
  );
};

export default HomeIcon;
