import * as Sentry from '@sentry/react';
import { getFirebaseToken } from '../auth/firebase-auth';
import requestConfig from '../../shared/config/request';
import APIError, { APIErrorData } from '../../shared/errors/api-error';
import NetworkError from '../../shared/errors/network-error';
import reportToSentry from '../../shared/utils/report-to-sentry';
import { getMinorVersion } from '../../shared/utils/versioning';

const clientIdentifierQueryParam = 'client=WEB';

enum Methods {
  POST = 'POST',
  PUT = 'PUT',
  GET = 'GET',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

async function parseResponseToJson<T>(response: Response) {
  if (response.headers.get('Content-Length') === '0') {
    return undefined as unknown as T;
  }

  try {
    const result = (await response.json()) as T;
    return result;
  } catch (parseToJsonError) {
    throw new Error('Failed to parse json response');
  }
}

export async function makeRequest<T>(
  url: string,
  options?: RequestInit,
  isAuthRequired: boolean = false,
  isPublicApi: boolean = false,
  hasFullUrl?: boolean,
  ignoreDefaultHeaders?: boolean,
  ignoreSentry?: boolean,
  signal?: AbortSignal
): Promise<T> {
  // init the token as a string or undefined
  let token: string | undefined;

  // if the flag isAuthRequired is true
  if (isAuthRequired) {
    // get the auth token from firebase and assign the same to token
    token = await getFirebaseToken();
  }

  let path = url;

  const [, search] = url.split('?');

  const version = await getMinorVersion();

  if (search) {
    path += `&${clientIdentifierQueryParam}&version=${version}`;
  } else {
    path += `?${clientIdentifierQueryParam}&version=${version}`;
  }

  const { method = Methods.GET, body, headers } = options ?? {};
  const fullUrl: string = hasFullUrl
    ? path
    : `${isPublicApi ? requestConfig.publicUrl : requestConfig.baseUrl}${path}`;
  let response: Response;
  try {
    response = await fetch(`${fullUrl}`, {
      method,
      body,
      headers: {
        ...(ignoreDefaultHeaders ? {} : defaultHeaders),
        ...headers,
        ...(token && { Authorization: token }),
      },
      signal,
    });

    if (!response.ok) {
      const errorMessage = `Request to ${url} failed with status ${response.status}`;
      let errMessage = 'An error occurred';
      let responseBody: any = null;
      try {
        // Check if the response's content type is JSON
        const contentType = response.headers.get('Content-Type');
        if (contentType && contentType.includes('application/json')) {
          responseBody = await response.json(); // Safely parse JSON
          errMessage = responseBody?.message || errorMessage; // Use message from response if available
        } else {
          // If not JSON, use response text instead
          errMessage = await response.text();
        }
      } catch (err) {
        console.error('Error parsing response:', err); // Handle JSON parsing error
        errMessage = 'Failed to parse response';
      }

      Sentry.captureException(new Error(errorMessage), {
        extra: {
          url,
          options,
          status: response.status,
          responseText: responseBody,
        },
      });
      throw new Error(errMessage);
    }
  } catch (requestError) {
    Sentry.captureException(requestError, {
      extra: {
        url,
        options,
        message: (requestError as Error)?.message,
      },
    });
    throw requestError as Error;
  }

  if (!response.ok) {
    const errorResult = await parseResponseToJson<APIErrorData>(response);
    const error = new APIError(errorResult);
    const ignorableErrors = [
      'failed to fetch',
      'too many requests',
      'rate limit exceeded',
      'forbidden resource',
      'code expired or not found',
      'whatsapp integration is not accessible',
      'user does not belong to the organization',
      'user does not have permission to perform this action',
      'file size is large than maximum allowed limit',
      'integration not found',
      'integration does not exist',
      'integration is not connected',
      'integration not found or not connected',
    ];
    const hasIgnorableError =
      ignorableErrors.findIndex((ignorableError) =>
        error.error?.toLowerCase?.()?.includes?.(ignorableError)
      ) > -1 ||
      ignorableErrors.findIndex((ignorableError) =>
        error.reason?.toLowerCase?.()?.includes?.(ignorableError)
      ) > -1 ||
      ignorableErrors.findIndex((ignorableError) =>
        error.message?.toLowerCase?.()?.includes?.(ignorableError)
      ) > -1;
    if (!ignoreSentry && !hasIgnorableError) {
      reportToSentry(error);
    }
    throw new APIError(errorResult);
  }

  if (response.status !== 204) {
    return parseResponseToJson<T>(response);
  }

  return undefined as unknown as T;
}

export async function get<T>(
  url: string,
  options?: RequestInit,
  isAuthRequired?: boolean,
  isPublicApi?: boolean,
  hasFullUrl?: boolean,
  ignoreDefaultHeaders?: boolean,
  ignoreSentry?: boolean,
  signal?: AbortSignal
): Promise<T> {
  return makeRequest<T>(
    url,
    {
      ...options,
      method: Methods.GET,
    },
    isAuthRequired,
    isPublicApi,
    hasFullUrl,
    ignoreDefaultHeaders,
    ignoreSentry,
    signal
  );
}

export async function post<T>(
  url: string,
  options?: RequestInit,
  isAuthRequired?: boolean,
  isPublicApi?: boolean,
  hasFullUrl?: boolean,
  ignoreDefaultHeaders?: boolean,
  ignoreSentry?: boolean
): Promise<T> {
  return makeRequest<T>(
    url,
    {
      ...options,
      method: Methods.POST,
    },
    isAuthRequired,
    isPublicApi,
    hasFullUrl,
    ignoreDefaultHeaders,
    ignoreSentry
  );
}

export async function put<T>(
  url: string,
  options?: RequestInit,
  isAuthRequired?: boolean,
  isPublicApi?: boolean,
  hasFullUrl?: boolean,
  ignoreDefaultHeaders?: boolean,
  ignoreSentry?: boolean
): Promise<T> {
  return makeRequest<T>(
    url,
    {
      ...options,
      method: Methods.PUT,
    },
    isAuthRequired,
    isPublicApi,
    hasFullUrl,
    ignoreDefaultHeaders,
    ignoreSentry
  );
}

export async function patch<T>(
  url: string,
  options?: RequestInit,
  isAuthRequired?: boolean,
  isPublicApi?: boolean,
  hasFullUrl?: boolean,
  ignoreDefaultHeaders?: boolean,
  ignoreSentry?: boolean
): Promise<T> {
  return makeRequest<T>(
    url,
    {
      ...options,
      method: Methods.PATCH,
    },
    isAuthRequired,
    isPublicApi,
    hasFullUrl,
    ignoreDefaultHeaders,
    ignoreSentry
  );
}

export async function deleteReq<T>(
  url: string,
  options?: RequestInit,
  isAuthRequired?: boolean,
  isPublicApi?: boolean,
  hasFullUrl?: boolean,
  ignoreDefaultHeaders?: boolean,
  ignoreSentry?: boolean
): Promise<T> {
  return makeRequest<T>(
    url,
    {
      ...options,
      method: Methods.DELETE,
    },
    isAuthRequired,
    isPublicApi,
    hasFullUrl,
    ignoreDefaultHeaders,
    ignoreSentry
  );
}
