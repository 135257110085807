import { useCallback, useEffect } from 'react';
import { getWabaIntegrations } from '../requests/waba';
import { useAtom } from 'jotai';
import { wabaIntegrationsCountAtom } from '../states/waba';
import { rootScope } from '../states/user';
import { WabaChannelStatus } from '../typings/waba';
import { useAuth } from './use-auth';

const useSingleWaba = (status?: WabaChannelStatus) => {
  const { authState: { isOrgSelected } } = useAuth();
  const [integrationsCount, setIntegrationsCount] = useAtom(
    wabaIntegrationsCountAtom,
    rootScope
  );

  const fetchWabas = useCallback(async () => {
    if (!isOrgSelected || typeof integrationsCount === 'number') {
      return;
    }
    const data = await getWabaIntegrations({
      status,
      hasMore: false,
    });
    const { integrationsCount: count } = data;
    setIntegrationsCount(count ?? 0);
    return data;
  }, [integrationsCount, setIntegrationsCount]);

  useEffect(() => {
    fetchWabas();
  }, [status]);

  return {
    wabaIntegrationsFetcher: fetchWabas,
    wabaIntegrationsCount: integrationsCount,
    hasMultipleWabas: (typeof integrationsCount === 'number') ? integrationsCount > 1 : false,
  };
};

export default useSingleWaba;
