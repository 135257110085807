import { FC, SVGProps } from 'react';

const SettingsIcon: FC<SVGProps<SVGSVGElement> & { size?: number }> = ({
  color = '#2d2d2d',
  size = 24,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path
        fill="none"
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M12,15c-1.654,0-3-1.346-3-3s1.346-3,3-3s3,1.346,3,3S13.654,15,12,15z"
      />
      <path
        fill="none"
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M18.899,10.868C18.967,11.285,19,11.655,19,12s-0.033,0.715-0.101,1.132l-0.089,0.549l1.738,1.512l-1.51,2.614l-2.172-0.748l-0.432,0.354c-0.577,0.474-1.238,0.854-1.965,1.128l-0.521,0.197L13.509,21h-3.018l-0.44-2.261l-0.521-0.197c-0.727-0.274-1.388-0.654-1.965-1.128l-0.432-0.354l-2.172,0.748l-1.51-2.614l1.738-1.512l-0.089-0.549C5.033,12.715,5,12.345,5,12s0.033-0.715,0.101-1.132l0.089-0.549L3.451,8.807l1.51-2.614l2.172,0.748l0.432-0.354c0.577-0.474,1.238-0.854,1.965-1.128l0.521-0.197L10.491,3h3.018l0.44,2.261l0.521,0.197c0.727,0.274,1.388,0.654,1.965,1.128l0.432,0.354l2.172-0.748l1.51,2.614l-1.738,1.512L18.899,10.868z"
      />
    </svg>
  );
};

export default SettingsIcon;
