/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useCustomNavigate } from '../../../../shared/hooks/use-conversation-navigate';
import { Close } from '@mui/icons-material';
import { postMessageToIframe } from '../../../../shared/utils/post-message';
import SettingsIframe from '../../../../shared/components/organisms/settings-iframe';
import QSModal from '../../../../shared/components/molecules/modal';
import {
  QSButton,
  QSIconButton,
  QSTypography,
} from '../../../../shared/components/atoms';
import { ROUTES } from '../../../../shared/constants/routes';
import { BORDER_COLOR } from '../../../../shared/typings';
import appConfig from '../../../../shared/config/app';
import { Box } from '@mui/material';
import useBotChangeHandler from '../../../../shared/hooks/use-bot-change-handler';

const DEFAULT_MODAL_TITLE = 'Bot Builder';

const BotBuilderModal = () => {
  const navigate = useCustomNavigate();
  const location = useLocation();

  const shouldGoBack =
    new URLSearchParams(location.search).get('goBack') === 'true';

  const { botId: botIdFromPathParams } = useParams<{ botId: string }>();
  const [botIdFromPostMessage, setBotIdFromPostMessage] = useState<
    string | null
  >(null);
  const botId = botIdFromPostMessage ?? botIdFromPathParams;

  const [open, setOpen] = useState<boolean>(
    typeof botId === 'string' && botId.length > 0
  );
  const [shouldCloseModalHeader, setShouldCloseModalHeader] =
    useState<boolean>(true);
  const [modalTitle, setModalTitle] = useState<string>(DEFAULT_MODAL_TITLE);
  const headerRef = useRef<HTMLDivElement | null>(null);
  const [isIFrameLoaded, setIsIFrameLoaded] = useState<boolean>(false);
  const [botIsDirty, setBotIsDirty] = useState<boolean>(false);

  const [showCloseModalConfirmationModal, setShowCloseModalConfirmationModal] =
    useState(false);

  const messageEventHandler = useCallback(
    ({
      data,
    }: MessageEvent<{ type: string; title: string; botId: string }>) => {
      if (typeof data === 'string') {
        try {
          data = JSON.parse(data);
        } catch (error) {
          // Do nothing, invalid json string received
        }
      }
      if (data.type === 'SHOW_MODAL_HEADER') {
        setShouldCloseModalHeader(true);
        setModalTitle(DEFAULT_MODAL_TITLE);
      } else if (data.type === 'HIDE_MODAL_HEADER') {
        setShouldCloseModalHeader(false);
        if (typeof data.title === 'string' && data.title.length > 0) {
          setModalTitle(data.title);
        }
      } else if (data.type === 'SET_BOT_BUILDER_MODAL_OPEN') {
        if (typeof data.botId === 'string' && data.botId.length > 0) {
          setOpen(true);
          setBotIdFromPostMessage(data.botId);
        }
      } else if (data.type === 'SET_BOT_BUILDER_MODAL_CLOSED') {
        setOpen(false);
      } else if (data.type === 'BOT_IS_DIRTY') {
        setBotIsDirty(true);
      } else if (data.type === 'BOT_IS_PRISTINE') {
        setBotIsDirty(false);
      }
    },
    []
  );

  const confirmCloseModalHeader = () => {
    setBotIdFromPostMessage(null);
    if (shouldGoBack) {
      navigate(-1);
      return;
    }
    if (
      typeof botIdFromPostMessage === 'string' &&
      botIdFromPostMessage.length > 0
    ) {
      setOpen(false);
      return;
    }
    navigate({ pathname: ROUTES.BOTS }, { replace: false });
  };

  const closeModalHeader = () => {
    if (botIsDirty) return setShowCloseModalConfirmationModal(true);
    if (shouldCloseModalHeader) {
      // setShowCloseModalConfirmationModal(true);
      setBotIdFromPostMessage(null);
      if (shouldGoBack) {
        navigate(-1);
        return;
      }
      if (
        typeof botIdFromPostMessage === 'string' &&
        botIdFromPostMessage.length > 0
      ) {
        setOpen(false);
        return;
      }
      navigate({ pathname: ROUTES.BOTS }, { replace: false });
    } else {
      postMessageToIframe(
        'bot-builder',
        JSON.stringify({ type: 'CLOSE_MODAL_HEADER' })
      );
    }
  };

  useEffect(() => {
    window.addEventListener('message', messageEventHandler);
    return () => {
      window.removeEventListener('message', messageEventHandler);
    };
  }, [messageEventHandler]);

  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      if (e.ctrlKey) {
        e.preventDefault();
        e.stopPropagation();
      }
    };
    const element = headerRef.current;
    if (element) {
      element.addEventListener('wheel', handleWheel, { passive: false });
    }
    return () => {
      element?.removeEventListener?.('wheel', handleWheel);
    };
  }, [headerRef.current, isIFrameLoaded]);

  useBotChangeHandler(botIsDirty);

  return (
    <>
      <QSModal
        open={open}
        onClose={() => navigate({ pathname: ROUTES.BOTS }, { replace: false })}
      >
        <div
          css={css`
            width: 95%;
            height: 95%;
            border-radius: 8px;a
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: white;
            overflow: hidden;
            &:focus-visible {
              outline: none;
            }
          `}
        >
          <div
            ref={headerRef}
            css={css`
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 45px;
              padding: 0 24px;
              border-bottom: 1px solid ${BORDER_COLOR};
              background: ${BORDER_COLOR}70;
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
            `}
          >
            <QSTypography
              css={css`
                font-weight: 600;
              `}
            >
              {modalTitle}
            </QSTypography>
            <QSIconButton sx={{}} onClick={closeModalHeader}>
              <Close />
            </QSIconButton>
          </div>
          <div
            css={css`
              height: calc(100% - 45px);
            `}
          >
            <SettingsIframe
              id="bot-builder"
              queryParams={[]}
              route={`/bots/${botId}`}
              title={modalTitle}
              iFrameHostUrl={appConfig.botBuilderHost}
              onLoadIframe={() => setIsIFrameLoaded(true)}
            />
          </div>
        </div>
      </QSModal>
      <QSModal
        open={showCloseModalConfirmationModal}
        onClose={() => setShowCloseModalConfirmationModal(false)}
      >
        <Box
          css={css`
            width: 100%;
            max-width: 350px;
            height: 100%;
            max-height: 132px;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            row-gap: 16px;
            padding: 16px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: white;
          `}
        >
          <QSTypography>
            Are you sure you want to close? Unsaved changes might be lost.
          </QSTypography>
          <Box
            css={css`
              display: flex;
              align-items: center;
              justify-content: flex-end;
              column-gap: 16px;
            `}
          >
            <QSButton
              variant="text"
              onClick={() => setShowCloseModalConfirmationModal(false)}
            >
              Cancel
            </QSButton>
            <QSButton onClick={confirmCloseModalHeader}>Confirm</QSButton>
          </Box>
        </Box>
      </QSModal>
    </>
  );
};

export default BotBuilderModal;
