/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { WabaIntegrationMemberStatus } from '../typings/waba';
import StatusAvailableIcon from '../icons/StatusAvailable';
import StatusBusyIcon from '../icons/StatusBusy';
import StatusOfflineIcon from '../icons/StatusOffline';
import StatusOnBreakIcon from '../icons/StatusOnBreak';
import StatusOnLeaveIcon from '../icons/StatusOnLeave';


export const getTeamMemberStatusDisplayLabel = (status: WabaIntegrationMemberStatus | null) => {
  switch (status) {
    case WabaIntegrationMemberStatus.ACTIVE: {
      return 'Active';
    }
    case WabaIntegrationMemberStatus.BUSY: {
      return 'Busy';
    }
    case WabaIntegrationMemberStatus.OFFLINE: {
      return 'Offline';
    }
    case WabaIntegrationMemberStatus.ON_BREAK: {
      return 'On Break';
    }
    case WabaIntegrationMemberStatus.ON_LEAVE: {
      return 'On Leave';
    }
    default: {
      return 'Offline';
    }
  };
}

export const getTeamMemberStatusStyles = (status: WabaIntegrationMemberStatus | null) => {
  switch (status) {
    case WabaIntegrationMemberStatus.ACTIVE: {
      return css`color: #007A5A; background-color: #E0EEEB;`;
    }
    case WabaIntegrationMemberStatus.BUSY: {
      return css`color: #C21312; background-color: #F9E2E3;`;
    }
    case WabaIntegrationMemberStatus.OFFLINE: {
      return css`color: #C21312; background-color: #F9E2E3;`;
    }
    case WabaIntegrationMemberStatus.ON_BREAK: {
      return css`color: #E8951D; background-color: #FBF6E2;`;
    }
    case WabaIntegrationMemberStatus.ON_LEAVE: {
      return css`color: #4285f4; background-color: #E7f0fE;`;
    }
    default: {
      return css`color: #007A5A; background-color: #E0EEEB;`;
    }
  };
};

export const getTeamMemberAvailabilityIcon = (status: WabaIntegrationMemberStatus | null) => {
  switch (status) {
    case WabaIntegrationMemberStatus.ACTIVE: {
      return StatusAvailableIcon;
    }
    default: {
      return StatusOfflineIcon;
    }
  };
};

export const getTeamMemberStatusIcon = (status: WabaIntegrationMemberStatus | null) => {
  switch (status) {
    case WabaIntegrationMemberStatus.ACTIVE: {
      return StatusAvailableIcon;
    }
    case WabaIntegrationMemberStatus.BUSY: {
      return StatusBusyIcon;
    }
    case WabaIntegrationMemberStatus.OFFLINE: {
      return StatusOfflineIcon;
    }
    case WabaIntegrationMemberStatus.ON_BREAK: {
      return StatusOnBreakIcon;
    }
    case WabaIntegrationMemberStatus.ON_LEAVE: {
      return StatusOnLeaveIcon;
    }
    default: {
      return StatusOfflineIcon;
    }
  };
};