import { atom } from 'jotai';
import { atomFamily, atomWithReset } from 'jotai/utils';
import {
  PaginatedQueryRequest,
  QueryRequest,
  QueryRequestFamilyParam,
  PaginatedQueryRequestFamilyParam,
} from '../typings/request';

type CreateRequestAtomParams<T> = { data?: T; initialLoading?: boolean };

type CreatePaginatedRequestAtomParams<T, K> = {
  data?: Array<T>;
  loading?: boolean;
  paginationData?: K & { hasMore: boolean };
};

export function createRequestAtom<T>({
  data,
  initialLoading,
}: CreateRequestAtomParams<T> = {}) {
  const requestDefaultAtom = atom<QueryRequest<T>>({
    loading: typeof initialLoading === 'boolean' ? initialLoading : !data,
    refreshing: !!data,
    data,
  });

  return requestDefaultAtom;
}

export function createRequestFamily<T>({
  data,
  initialLoading,
}: CreateRequestAtomParams<T> = {}) {
  const requestsDefaultFamily = atomFamily(
    (param: QueryRequestFamilyParam<T>) => {
      return atom<QueryRequest<T>>({
        loading: typeof initialLoading === 'boolean' ? initialLoading : !data,
        refreshing: !!data,
        data,
      });
    },
    (a: QueryRequestFamilyParam<T>, b: QueryRequestFamilyParam<T>) =>
      a.id === b.id
  );

  return requestsDefaultFamily;
}

export function createPaginatedRequestAtom<T, K>({
  data,
  loading,
  paginationData,
}: CreatePaginatedRequestAtomParams<T, K> = {}) {
  const requestDefaultAtom = atomWithReset<PaginatedQueryRequest<T, K>>({
    loading: typeof loading !== 'undefined' ? loading : !data,
    isFetchingMore: false,
    data,
    paginationData,
  });

  return requestDefaultAtom;
}

export function createPaginatedRequestFamily<T, K>({
  data,
  loading,
  paginationData,
}: CreatePaginatedRequestAtomParams<T, K> = {}) {
  const requestsDefaultFamily = atomFamily(
    (param: PaginatedQueryRequestFamilyParam<T, K>) => {
      return atomWithReset<PaginatedQueryRequest<T, K>>({
        loading: typeof loading !== 'undefined' ? loading : !data,
        isFetchingMore: false,
        data,
        fetched: false,
        paginationData,
      });
    },
    (
      a: PaginatedQueryRequestFamilyParam<T, K>,
      b: PaginatedQueryRequestFamilyParam<T, K>
    ) => a.id === b.id
  );

  return requestsDefaultFamily;
}
