import { rootScope } from '../states/user';
import { useAtomValue } from 'jotai';
import { userRoleAtom } from '../states/user';
import { Role } from '../typings/user';

export const useRole = () => {
  const role = useAtomValue(userRoleAtom, rootScope);

  return {
    isOwner: Role.Owner === role,
    isTeamMember: Role.TeamMember === role,
    isTeamMemberAssigned: Role.TeamMemberAssigned === role,
    isDemoUser: Role.DemoUser === role,
    isTeamLead: Role.TeamLead === role,
  };
};
