import { useEffect } from 'react';

const usePlayNotificationSound = () => {
  useEffect(() => {
    const playNotificationSound = (event: MessageEvent) => {
      if (event.data && event.data.type === 'DT_PLAY_SOUND') {
        const audio = new Audio('/notification-alert.mp3'); // Path to your sound file
        audio.play().catch((error) => {
          console.error('Sound playback failed:', error);
        });
      }
    };

    navigator.serviceWorker.addEventListener('message', playNotificationSound);

    return () => {
      navigator.serviceWorker.removeEventListener(
        'message',
        playNotificationSound
      );
    };
  }, []);

  useEffect(() => {
    const playNotificationSound = (event: MessageEvent) => {
      if (event.data && event.data.type === 'DT_PLAY_SOUND') {
        const audio = new Audio('/notification-alert.mp3'); // Path to your sound file
        audio.play().catch((error) => {
          console.error('Sound playback failed:', error);
        });
      }
    };

    window.addEventListener('message', playNotificationSound);

    return () => {
      window.removeEventListener('message', playNotificationSound);
    };
  }, []);

  return null;
};

export default usePlayNotificationSound;
