export const postMessageToIframe = (id: string, msg: any) => {
  let iframe = document.getElementById(id) as HTMLIFrameElement;
  if (iframe) {
    let iframeContent = iframe.contentWindow || iframe.contentDocument;
    if (iframeContent) {
      (
        iframeContent as (Document | Window) & {
          postMessage: (...args: any) => void;
        }
      ).postMessage(msg, '*');
    }
  }
};
