import { FirebaseOptions } from 'firebase/app';
import { getUserChannelData } from '../../modules/login/v1/utility';

let config: FirebaseOptions;
const cpDetails = getUserChannelData();

if (
  process.env.REACT_APP_ENV === 'production' ||
  process.env.REACT_APP_ENV === 'production-2' ||
  process.env.REACT_APP_ENV === 'production-local'
) {
  const apiKey =
    cpDetails?.firebaseApiKey || 'AIzaSyBY5QU_ls7X_K9jO-7EubyM8Z-7duDabHI';

  config = {
    apiKey: apiKey,
    authDomain: 'quickscale-crm.firebaseapp.com',
    projectId: 'quickscale-crm',
    messagingSenderId: '791667582399',
    appId: '1:791667582399:web:3c7e712cf48e070d286d0a',
    measurementId: 'G-5SSRMEBVP2',
    storageBucket: 'quickscale-crm.appspot.com',
    databaseURL: `https://quickscale-crm-default-rtdb.asia-southeast1.firebasedatabase.app/`,
  };
} else if (
  process.env.REACT_APP_ENV === 'apiStaging' ||
  process.env.REACT_APP_ENV === 'apiStaging2' ||
  process.env.REACT_APP_ENV === 'apiStaging3' ||
  process.env.REACT_APP_ENV === 'stagingWithProd' ||
  process.env.REACT_APP_ENV === 'staging2WithProd'
) {
  const apiKey =
    cpDetails?.firebaseApiKey || 'AIzaSyBY5QU_ls7X_K9jO-7EubyM8Z-7duDabHI';

  config = {
    apiKey: apiKey,
    authDomain: 'quickscale-crm.firebaseapp.com',
    projectId: 'quickscale-crm',
    messagingSenderId: '791667582399',
    appId: '1:791667582399:web:3c7e712cf48e070d286d0a',
    measurementId: 'G-5SSRMEBVP2',
    storageBucket: 'quickscale-crm.appspot.com',
    databaseURL: `https://quickscale-crm-default-rtdb.asia-southeast1.firebasedatabase.app/`,
  };
} else {
  const apiKey =
    cpDetails?.firebaseApiKey || 'AIzaSyBVL1VHDobUHTF7yH0OMaJljYUz2EE9kJs';
  config = {
    apiKey: apiKey,
    authDomain: 'whatsapp-crm-dev.firebaseapp.com',
    databaseURL: 'https://whatsapp-crm-dev-default-rtdb.firebaseio.com',
    projectId: 'whatsapp-crm-dev',
    storageBucket: 'whatsapp-crm-dev.appspot.com',
    messagingSenderId: '39247812771',
    appId: '1:39247812771:web:a9c91a4aeec97218ca4716',
    measurementId: 'G-ZB6QRS9B4B',
  };
}

export default config;
