export interface WalletDetails {
  currencyCode: string;
  balance: number;
  rechargeLink: string;
  isSendingChatMessageAllowed: boolean;
  remainingFreeMessages: number;
  generatePaymentLink: boolean;
}

export const currency_symbols: { [key: string]: string } = {
  USD: '$', // US Dollar
  EUR: '€', // Euro
  CRC: '₡', // Costa Rican Colón
  GBP: '£', // British Pound Sterling
  ILS: '₪', // Israeli New Sheqel
  INR: '₹', // Indian Rupee
  JPY: '¥', // Japanese Yen
  KRW: '₩', // South Korean Won
  NGN: '₦', // Nigerian Naira
  PHP: '₱', // Philippine Peso
  PLN: 'zł', // Polish Zloty
  PYG: '₲', // Paraguayan Guarani
  THB: '฿', // Thai Baht
  UAH: '₴', // Ukrainian Hryvnia
  VND: '₫', // Vietnamese Dong
};

export interface RechargeWalletRequest {
  amount: number;
  redirectUrl: string;
}

export interface RechargeWalletResponse {
  paymentLink: string;
}
