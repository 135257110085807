import { Message } from '../typings/message-types';
import {
  getDate,
  timeString,
} from '../../../../shared/utils/get-time-from-timestamp';

const messageDateCache: {
  [chatId: string]: {
    groups: Record<string, Set<number>>;
    length: number;
  };
} = {};

const getDateForMessageGroup = (timestamp: number) => getDate(timestamp);

const todayDate = getDateForMessageGroup(Date.now());
const yesterdayDate = getDateForMessageGroup(Date.now() - 86400000);

const getGroupedMessages = (data: Message[]) => {
  return data.reduce<Record<string, Set<number>>>((acc, message, i) => {
    let key = getDateForMessageGroup(message.messageTime);

    if (key === todayDate) {
      key = timeString.TODAY;
    } else if (key === yesterdayDate) {
      key = timeString.YESTERDAY;
    }

    if (!acc[key]) {
      acc[key] = new Set<number>();
    }
    acc[key].add(i);

    return acc;
  }, {});
};

const getMessageDateGroups = (chatId: string, data: Message[]) => {
  if (!messageDateCache[chatId]?.length) {
    const groupedMessages = getGroupedMessages(data);

    messageDateCache[chatId] = { groups: groupedMessages, length: data.length };
  } else {
    const newData = data.slice(messageDateCache[chatId].length - 1);
    const newGroupedMessages = getGroupedMessages(newData);

    if (!!newGroupedMessages) {
      Object.keys(newGroupedMessages).forEach((key) => {
        if (!messageDateCache[chatId].groups[key]) {
          messageDateCache[chatId].groups[key] = new Set<number>();
        }
        newGroupedMessages[key].forEach((i) =>
          messageDateCache[chatId].groups[key].add(
            i + messageDateCache[chatId].length - 1
          )
        );
      });
    }

    messageDateCache[chatId].length = data.length;
  }

  const groupKeys = Object.keys(messageDateCache[chatId].groups);
  const groupCounts = Object.values(messageDateCache[chatId].groups).map(
    (arr) => arr.size
  );

  return { groupCounts, groupKeys };
};

export default getMessageDateGroups;
