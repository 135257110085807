import { ReactNode, ElementType } from 'react';
import {
  Tooltip,
  TooltipProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import { isTextRTL } from '../../../../modules/home/v1/utils/rtl-text';

export type QSTypographyProps<P extends ElementType<any>> = TypographyProps<
  P,
  {
    children?: ReactNode;
    component?: P;
    tooltipProps?: Omit<TooltipProps, 'children'>;
  }
>;

function QSTypography<P extends ElementType<any> = 'span'>({
  sx,
  children,
  component,
  ...props
}: QSTypographyProps<P>) {
  let direction;
  if (typeof children === 'string') {
    direction = isTextRTL(children) ? 'rtl' : 'ltr';
  }

  return (
    <Typography
      component={component}
      sx={{
        ...sx,
      }}
      {...props}
      dir={props.dir ? props.dir : direction}
    >
      {children ? children : null}
    </Typography>
  );
}

export function QSTypographyTooltip<P extends ElementType<any> = 'span'>({
  sx,
  children,
  component,
  tooltipProps,
  ...props
}: QSTypographyProps<P>) {
  return (
    <Tooltip
      placement="top"
      {...tooltipProps}
      title={tooltipProps?.title ? tooltipProps?.title : children}
    >
      <Typography
        component={component}
        sx={{
          ...sx,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        {...props}
      >
        {children ? children : null}
      </Typography>
    </Tooltip>
  );
}

export default QSTypography;
