import { FC, SVGProps } from 'react';

const UpdateStatusIcon: FC<SVGProps<SVGSVGElement>> = ({ height = 18, width = 18, fill = '#C21313', ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 18 18"
      width={width}
      height={height}
      fill="none"
      {...props}
    >
      <path
        d="M9 18C7.75 18 6.57917 17.7625 5.4875 17.2875C4.39583 16.8125 3.44583 16.1708 2.6375 15.3625C1.82917 14.5542 1.1875 13.6042 0.7125 12.5125C0.2375 11.4208 0 10.25 0 9C0 7.75 0.2375 6.57917 0.7125 5.4875C1.1875 4.39583 1.82917 3.44583 2.6375 2.6375C3.44583 1.82917 4.39583 1.1875 5.4875 0.7125C6.57917 0.2375 7.75 0 9 0C10.3667 0 11.6625 0.291667 12.8875 0.875C14.1125 1.45833 15.15 2.28333 16 3.35V2C16 1.71667 16.0958 1.47917 16.2875 1.2875C16.4792 1.09583 16.7167 1 17 1C17.2833 1 17.5208 1.09583 17.7125 1.2875C17.9042 1.47917 18 1.71667 18 2V6C18 6.28333 17.9042 6.52083 17.7125 6.7125C17.5208 6.90417 17.2833 7 17 7H13C12.7167 7 12.4792 6.90417 12.2875 6.7125C12.0958 6.52083 12 6.28333 12 6C12 5.71667 12.0958 5.47917 12.2875 5.2875C12.4792 5.09583 12.7167 5 13 5H14.75C14.0667 4.06667 13.225 3.33333 12.225 2.8C11.225 2.26667 10.15 2 9 2C7.05 2 5.39583 2.67917 4.0375 4.0375C2.67917 5.39583 2 7.05 2 9C2 10.95 2.67917 12.6042 4.0375 13.9625C5.39583 15.3208 7.05 16 9 16C10.5833 16 12 15.525 13.25 14.575C14.5 13.625 15.325 12.4 15.725 10.9C15.8083 10.6333 15.9583 10.4333 16.175 10.3C16.3917 10.1667 16.6333 10.1167 16.9 10.15C17.1833 10.1833 17.4083 10.3042 17.575 10.5125C17.7417 10.7208 17.7917 10.95 17.725 11.2C17.2417 13.1833 16.1917 14.8125 14.575 16.0875C12.9583 17.3625 11.1 18 9 18ZM10 8.6L12.5 11.1C12.6833 11.2833 12.775 11.5167 12.775 11.8C12.775 12.0833 12.6833 12.3167 12.5 12.5C12.3167 12.6833 12.0833 12.775 11.8 12.775C11.5167 12.775 11.2833 12.6833 11.1 12.5L8.3 9.7C8.2 9.6 8.125 9.4875 8.075 9.3625C8.025 9.2375 8 9.10833 8 8.975V5C8 4.71667 8.09583 4.47917 8.2875 4.2875C8.47917 4.09583 8.71667 4 9 4C9.28333 4 9.52083 4.09583 9.7125 4.2875C9.90417 4.47917 10 4.71667 10 5V8.6Z"
        fill={fill}
      />
    </svg>
  );
};

export default UpdateStatusIcon;
