import { get, post } from '../../infra/rest';
import { UserStatusData } from '../typings/user';
import { WabaIntegrationMemberStatus } from '../typings/waba';

export const getUserStatus = async (): Promise<UserStatusData> => {
  return get<UserStatusData>(`/v1/organization/user/availability/status`, undefined, true);
};

export const setUserStatus = async ({ status, statusExpiration, teamId }: { status: WabaIntegrationMemberStatus | null; statusExpiration: string | null; teamId: string; }): Promise<UserStatusData> => {
  return post<UserStatusData>(
    `/v1/organization/user/availability/status`,
    {
      body: JSON.stringify({ status, statusExpiration, teamId }),
    },
    true
  );
};
