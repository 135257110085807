import { atom, useAtom } from 'jotai';
import { createRequestAtom } from '../../../../shared/utils/request-atom';
import { CustomFieldsData } from '../../../home/v1/typings';
import {
  ShopifyEventType,
  ShopifyEventsStatus,
  ShopifyStateData,
} from '../typings';
import { ShopifySignupDetails } from '../components/shopify-signup';
import { rootScope } from '../../../../shared/states/user';
import { atomWithReset } from 'jotai/utils';

export const shopifyEventStatusAtom = atom<ShopifyEventsStatus>({
  [ShopifyEventType.CART_ABANDONMENT]: false,
  [ShopifyEventType.ORDER_COMPLETION]: false,
  [ShopifyEventType.ORDER_FULFILLED]: false,
  [ShopifyEventType.FOLLOW_UP_POST_PURCHASE]: false,
});

export const CustomVariablesAtom =
  createRequestAtom<Record<ShopifyEventType, Record<string, string>>>();

export const shopifyStateAtom = createRequestAtom<ShopifyStateData>();

export const shopifyCustomFieldsAtom = createRequestAtom<
  CustomFieldsData | undefined
>();

export const shopifySignupDetailsAtom = atomWithReset<ShopifySignupDetails>({
  firstName: '',
  lastName: '',
  orgName: '',
  designation: '',
  websiteUrl: '',
  email: '',
  shopId: '',
});

export const useShopifySignupDetailsAtom = () =>
  useAtom(shopifySignupDetailsAtom, rootScope);
