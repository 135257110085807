/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box } from '@mui/material';
import NegativeBalanceHero from '../../../../shared/assets/animations/NegativeBalanceHero.json';
import { QSButton, QSTypography } from '../../../../shared/components/atoms';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { walletDetailsAtom } from '../../../../shared/states/wallet';
import { currency_symbols } from '../../../../shared/typings/wallet';
import { openPopup } from '../../../../shared/utils/popup';
import requestConfig from '../../../../shared/config/request';
import { getFirebaseToken } from '../../../../infra/auth/firebase-auth';
import { useAtomValue } from 'jotai';
import QSModal from '../modal';
import Lottie from 'lottie-react';

interface NegativeWalletBalanceProps {
  showNegativeWalletBalanceModal: boolean;
}

const NegativeWalletBalance = ({
  showNegativeWalletBalanceModal,
}: NegativeWalletBalanceProps) => {
  const { data: walletDetails, loading: walletDetailsLoading } =
    useAtomValue(walletDetailsAtom);

  const handleOnClickRecharge = async () => {
    if (!walletDetails || walletDetailsLoading) return;
    if (walletDetails.generatePaymentLink) {
      const token = await getFirebaseToken();

      openPopup(
        `${requestConfig.dtCallbackUrl}/wallet?token=${token}`,
        '_self'
      );
    } else window.open(walletDetails.rechargeLink, '_blank');
  };

  if (!walletDetails) {
    return null;
  }

  return (
    <QSModal open={showNegativeWalletBalanceModal}>
      <Box
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          row-gap: 40px;
          padding: 56px 28px 28px 28px;
          width: 100%;
          max-width: 600px;
          height: auto;
          max-height: 450px;
          background-color: #fff;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          border: 1px solid #e0e0e9;
          border-radius: 20px;
          outline: none;
        `}
      >
        <Box
          css={css`
            display: flex;
            width: 191px;
            height: 120px;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background: #fff;
          `}
        >
          <Lottie animationData={NegativeBalanceHero} />
        </Box>
        <Box
          css={css`
            display: flex;
            flex-direction: column;
            row-gap: 8px;
            align-items: center;
            justify-content: center;
          `}
        >
          <QSTypography
            css={css`
              font-size: 24px;
              font-weight: 600;
            `}
          >
            Uh-Oh! You have overspent your wallet credits
          </QSTypography>
          <QSTypography
            css={css`
              font-size: 14px;
              font-weight: 400;
              color: #7a7a7a;
            `}
          >
            Please recharge to continue using DoubleTick
          </QSTypography>
        </Box>
        <Box
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 16px 28px;
            border-radius: 12px;
            background-color: #eaeaea;
            width: 100%;
            max-width: 544px;
          `}
        >
          <Box
            css={css`
              display: flex;
              flex-direction: column;
              row-gap: 8px;
            `}
          >
            <Box
              css={css`
                display: flex;
                column-gap: 8px;
                align-items: center;
              `}
            >
              <AccountBalanceWalletIcon
                css={css`
                  color: #7a7a7a;
                  font-size: 18px;
                `}
              />
              <QSTypography
                css={css`
                  font-size: 14px;
                  color: #7a7a7a;
                `}
              >
                Wallet Balance
              </QSTypography>
            </Box>
            <QSTypography
              css={css`
                font-size: 20px;
                font-weight: 500;
                color: #c21313;
              `}
            >
              <span
                css={css`
                  padding-right: 8px;
                `}
              >
                -
              </span>
              <span
                css={css`
                  padding-right: 8px;
                `}
              >
                {currency_symbols[walletDetails?.currencyCode] ||
                  walletDetails?.currencyCode}
              </span>
              <span>{walletDetails?.balance.toString().split('-')[1]}</span>
            </QSTypography>
          </Box>
          <QSButton onClick={handleOnClickRecharge}>Recharge Now</QSButton>
        </Box>
      </Box>
    </QSModal>
  );
};

export default NegativeWalletBalance;
