let englishOrdinalRules: Intl.PluralRules | undefined;

if (typeof Intl !== 'undefined' && typeof Intl.PluralRules === 'function') {
  englishOrdinalRules = new Intl.PluralRules('en', { type: 'ordinal' });
}

export const ordinals = {
  zero: 'th',
  one: 'st',
  two: 'nd',
  few: 'rd',
  many: 'th',
  other: 'th',
};

export const addOrdinal = (day: number): string => {
  if (!englishOrdinalRules) {
    return `${day}`;
  }

  const category = englishOrdinalRules.select(day);
  const suffix = ordinals[category];
  return day + suffix;
};

export const formatMinutesAsLongDaysHoursMinutesSeconds = (minutes: number) => {
  const days = Math.floor(minutes / 1440);
  const remainingHours = Math.floor((minutes % 1440) / 60);
  const remainingMinutes = minutes % 60;
  if (days > 0) {
    if (remainingHours > 0) {
      return `${days} ${days > 1 ? 'days' : 'day'} : ${remainingHours} ${remainingHours > 1 ? 'hours' : 'hour'} : ${remainingMinutes.toString().padStart(2, '0')} ${remainingMinutes > 1 ? 'minutes' : 'minute'}`;
    }
    return `${days} ${days > 1 ? 'days' : 'day'} : ${remainingMinutes.toString().padStart(2, '0')} ${remainingMinutes > 1 ? 'minutes' : 'minute'}`;
  } else {
    if (remainingHours > 0) {
      if (remainingMinutes > 0) {
        return `${remainingHours} ${remainingHours > 1 ? 'hours' : 'hour'} : ${remainingMinutes.toString().padStart(2, '0')} ${remainingMinutes > 1 ? 'minutes' : 'minute'}`;
      }
      return `${remainingHours} ${remainingHours > 1 ? 'hours' : 'hour'}`;
    } else {
      return `${remainingMinutes} ${remainingMinutes > 1 ? 'minutes' : 'minute'}`;
    }
  }
};

export const formatMinutesAsLongHoursMinutesSeconds = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  if (hours > 0) {
    if (remainingMinutes > 0) {
      return `${hours} ${hours > 1 ? 'hours' : 'hour'} : ${remainingMinutes.toString().padStart(2, '0')} ${remainingMinutes > 1 ? 'minutes' : 'minute'}`;
    }
    return `${hours} ${hours > 1 ? 'hours' : 'hour'}`;
  } else {
    return `${remainingMinutes} ${remainingMinutes > 1 ? 'minutes' : 'minute'}`;
  }
}

export const formatSecondsAsLongHoursMinutesSeconds = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.round(seconds % 60);
  if (hours > 0) {
    return `${hours} ${hours > 1 ? 'hours' : 'hour'} : ${minutes.toString().padStart(2, '0')} ${minutes > 1 ? 'minutes' : 'minute'} and ${remainingSeconds.toString().padStart(2, '0')} ${remainingSeconds > 1 ? 'seconds' : 'second'}`;
  } else {
    if (minutes > 0) {
      return `${minutes} ${minutes > 1 ? 'minutes' : 'minute'} : ${remainingSeconds.toString().padStart(2, '0')} ${remainingSeconds > 1 ? 'seconds' : 'second'}`;
    } else {
      return `${remainingSeconds} ${remainingSeconds > 1 ? 'seconds' : 'second'}`;
    }
  }
};

export const formatMinutesAsShortDaysHoursMinutesSeconds = (minutes: number, abbreviateMinutes?: boolean) => {
  const days = Math.floor(minutes / 1440);
  const remainingHours = Math.floor((minutes % 1440) / 60);
  const remainingMinutes = minutes % 60;
  const remainingSeconds = Math.round((minutes % 1) * 60);
  if (days > 0) {
    if (remainingHours > 0) {

      if (remainingMinutes > 0) {
        return `${days}${abbreviateMinutes ? 'd' : `${days > 1 ? ' days' : ' day'}`} : ${remainingHours}${abbreviateMinutes ? 'h' : (remainingHours > 1 ? ' hours' : ' hour')} : ${remainingMinutes}${abbreviateMinutes ? 'm' : (remainingMinutes > 1 ? ' minutes' : ' minute')}`;
      }

      return `${days}${abbreviateMinutes ? 'd' : `${days > 1 ? ' days' : ' day'}`} : ${remainingHours}${abbreviateMinutes ? 'h' : (remainingHours > 1 ? ' hours' : ' hour')}`;
    }
    return `${days}${days > 1 ? ' days' : ' day'}`;
  } else {
    if (remainingHours > 0) {
      if (remainingMinutes > 0) {
        return `${remainingHours}${abbreviateMinutes ? 'h' : `${remainingHours > 1 ? ' hours' : ' hour'}`} : ${remainingMinutes.toString().padStart(2, '0')}${abbreviateMinutes ? 'm' : (remainingMinutes > 1 ? ' minutes' : ' minute')}`;
      }
      return `${remainingHours}${remainingHours > 1 ? ' hours' : ' hour'}`;
    } else {
      if (remainingSeconds > 0) {
        return `${remainingMinutes}${abbreviateMinutes ? 'm' : `${remainingMinutes > 1 ? ' minutes' : ' minute'}`} : ${remainingSeconds}${abbreviateMinutes ? 's' : (remainingSeconds > 1 ? ' seconds' : ' second')}`;
      }
      return `${remainingMinutes}${abbreviateMinutes ? 'm' : ` ${remainingMinutes > 1 ? ' minutes' : ' minute'}`}`;
    }
  }
};

export const formatMinutesAsShortHoursMinutesSeconds = (minutes: number, abbreviateMinutes?: boolean) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  const remainingSeconds = Math.round((minutes % 1) * 60);
  if (hours > 0) {
    if (remainingMinutes > 0) {
      return `${hours}h : ${remainingMinutes.toString().padStart(2, '0')}m`;
    }
    return `${hours}${abbreviateMinutes ? 'h' : hours > 1 ? ' hours' : ' hour'}`;
  } else {
    if (remainingSeconds > 0) {
      return `${remainingMinutes}${abbreviateMinutes ? 'm' : (remainingMinutes > 1 ? ' minutes' : ' minute')} : ${remainingSeconds}${abbreviateMinutes ? 's' : (remainingSeconds > 1 ? ' seconds' : ' second')}`;
    }
    return `${remainingMinutes}${abbreviateMinutes ? 'm' : (remainingMinutes > 1 ? ' minutes' : ' minute')}`;
  }
}

export const formatSecondsAsShortHoursMinutesSeconds = (seconds: number, abbreviateSeconds?: boolean) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.round(seconds % 60);
  if (hours > 0) {
    return `${hours}h : ${minutes.toString().padStart(2, '0')}m`;
  } else {
    if (minutes > 0) {
      return `${minutes}m : ${remainingSeconds.toString().padStart(2, '0')}s`;
    } else {
      return `${remainingSeconds}${abbreviateSeconds ? 's' : (remainingSeconds > 1 ? ' seconds' : ' second')}`;
    }
  }
};
