import { useCallback, useEffect } from 'react';
import { getOrganizationsSocket } from '../../../../infra/sockets/organization-socket';
import useSocket from '../../../../shared/hooks/use-socket';
import { AgentStatusSocket } from '../typings';
import { postMessageToIframe } from '../../../../shared/utils/post-message';

const useAgentStatusSocket = () => {
  const { socket: organisationSocket } = useSocket(getOrganizationsSocket);

  const agentStatusListener = useCallback(
    (payload: AgentStatusSocket) => {
      const {
        userId,
        teamId,
        memberId,
        status,
        statusExpiration,
      } = payload;
      if (typeof userId === 'string' && userId.length > 0) {
        postMessageToIframe(
          'profile',
          JSON.stringify({
            type: 'SET_USER_STATUS',
            data: { status, statusExpiration },
          }),
        );
      } else if (((typeof teamId === 'string' && teamId.length > 0) && typeof memberId === 'string' && memberId.length > 0)) {
        postMessageToIframe(
          'team-management',
          JSON.stringify({
            type: 'SET_AGENT_STATUS',
            data: { teamId, memberId, status, statusExpiration },
          }),
        );
      }
    },
    []
  );

  useEffect(() => {
    organisationSocket.on('team-member-availability-updates', agentStatusListener);
    return () => {
      organisationSocket.off('team-member-availability-updates', agentStatusListener);
    };
  }, [agentStatusListener, organisationSocket]);
};

export default useAgentStatusSocket;
