import { deleteApp, FirebaseApp, getApp, initializeApp } from 'firebase/app';
import { Auth, getAuth } from 'firebase/auth';
import {
  getMessaging,
  getToken,
  deleteToken,
  isSupported,
  Messaging,
} from 'firebase/messaging';
import config from '../../shared/config/firebase';
import appConfig from '../../shared/config/app';

export let firebaseApp = initializeApp(config);
export let firebaseAuth = getAuth(firebaseApp);
export let messaging = getMessaging();

export const initializeFirebaseApp = async (apiKey: string) => {
  let newConfig = {
    ...config,
  };
  if (apiKey && config.apiKey === apiKey) {
    return firebaseApp;
  }
  if (apiKey) {
    newConfig.apiKey = apiKey;
  }
  try {
    // If an app already exists, delete it first
    const existingApp = getApp();
    if (existingApp) {
      await deleteApp(existingApp);
    }
  } catch (error) {
    // If no app exists, ignore the error (since it's not initialized yet)
  }
  firebaseApp = initializeApp(newConfig);
  firebaseAuth = getAuth(firebaseApp);
  messaging = getMessaging(firebaseApp);
  return firebaseApp;
};

export const getCurrentUserId = () => firebaseAuth?.currentUser?.uid;

export const getMessagingToken = (registration?: ServiceWorkerRegistration) => {
  return getToken(messaging, {
    vapidKey: appConfig.firebaseVapidKey,
    serviceWorkerRegistration: registration,
  });
};

export const isFirebaseMessagingSupported = () => {
  return isSupported();
};

const DB_NAME = 'DTREQUESTS';
const DB_VERSION = 1;
const DB_STORE_NAME = 'requests';

const getIndexDB = ({
  name = DB_NAME,
  version = DB_VERSION,
  storeName = DB_STORE_NAME,
} = {}) => {
  if (!('indexedDB' in window)) {
    throw new Error('Browser does not support IndexedDB');
  }

  return new Promise((resolve, reject) => {
    try {
      const request = window.indexedDB.open(name, version);

      request.onerror = (event) => {
        reject(event);
      };

      request.onsuccess = (event) => {
        const db = request.result;
        resolve(db);
      };

      request.onupgradeneeded = (event) => {
        if (!event.target) {
          reject(event);
          return;
        }

        const db = request.result;
        db.createObjectStore(storeName, {
          keyPath: 'id',
        });

        resolve(db);
      };
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

const deleteRequests = async () => {
  const db = (await getIndexDB()) as IDBDatabase;

  return new Promise((resolve, reject) => {
    try {
      const transaction = db.transaction(DB_STORE_NAME, 'readwrite');

      transaction.onerror = (event: Event) => {
        reject(event);
      };

      const objectStore = transaction.objectStore(DB_STORE_NAME);
      const request = objectStore.clear();

      request.onerror = (event: Event) => {
        reject(event);
      };

      request.onsuccess = () => {
        resolve('');
      };
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const deleteMessagingToken = async () => {
  const allow = await isSupported();
  if (allow) {
    deleteRequests();
    return deleteToken(messaging);
  }
};
