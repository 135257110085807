import { FC, SVGProps } from 'react';

const StatusOnBreakIcon: FC<SVGProps<SVGSVGElement>> = ({ height = 16, width = 16, fill = 'none', ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 16 16"
      width={width}
      height={height}
      fill="none"
      {...props}
    >
      <path
        d="M1.16659 15.5C0.930474 15.5 0.732558 15.4201 0.572835 15.2604C0.413113 15.1007 0.333252 14.9028 0.333252 14.6667C0.333252 14.4306 0.413113 14.2326 0.572835 14.0729C0.732558 13.9132 0.930474 13.8333 1.16659 13.8333H12.8333C13.0694 13.8333 13.2673 13.9132 13.427 14.0729C13.5867 14.2326 13.6666 14.4306 13.6666 14.6667C13.6666 14.9028 13.5867 15.1007 13.427 15.2604C13.2673 15.4201 13.0694 15.5 12.8333 15.5H1.16659ZM3.66659 12.1667C2.74992 12.1667 1.9652 11.8403 1.31242 11.1875C0.659641 10.5347 0.333252 9.75 0.333252 8.83333V2.16667C0.333252 1.70833 0.496446 1.31597 0.822835 0.989583C1.14922 0.663194 1.54159 0.5 1.99992 0.5H13.6666C14.1249 0.5 14.5173 0.663194 14.8437 0.989583C15.1701 1.31597 15.3333 1.70833 15.3333 2.16667V4.66667C15.3333 5.125 15.1701 5.51736 14.8437 5.84375C14.5173 6.17014 14.1249 6.33333 13.6666 6.33333H11.9999V8.83333C11.9999 9.75 11.6735 10.5347 11.0208 11.1875C10.368 11.8403 9.58325 12.1667 8.66659 12.1667H3.66659ZM3.66659 10.5H8.66659C9.12492 10.5 9.51728 10.3368 9.84367 10.0104C10.1701 9.68403 10.3333 9.29167 10.3333 8.83333V2.16667H1.99992V8.83333C1.99992 9.29167 2.16311 9.68403 2.4895 10.0104C2.81589 10.3368 3.20825 10.5 3.66659 10.5ZM11.9999 4.66667H13.6666V2.16667H11.9999V4.66667ZM3.66659 10.5H1.99992H10.3333H3.66659Z"
        fill="#E7951C"
      />
    </svg>
  );
};

export default StatusOnBreakIcon;
