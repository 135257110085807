import { Button, ButtonProps } from '@mui/material';

export default function QSButton({
  sx,
  variant = 'contained',
  ...props
}: ButtonProps) {
  return (
    <Button
      sx={{
        borderRadius: '4px',
        ...sx,
      }}
      variant={variant}
      {...props}
    />
  );
}

export type { ButtonProps };
