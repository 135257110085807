import { BusinessProfileDetails, BusinessProfileRequest } from '../typings/business-profile';
import { get } from '../../infra/rest';

export const getBusinessProfileDetails = async (): Promise<BusinessProfileDetails> => {
  return get<BusinessProfileRequest>(
    `/v1/organization/integration/profile`,
    undefined,
    true
  );
};

export default getBusinessProfileDetails;
