import { FC, SVGProps } from 'react';

const BotsIcon: FC<SVGProps<SVGSVGElement>> = ({
  color = '#2d2d2d',
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M 12 2 A 2 2 0 0 0 10 4 A 2 2 0 0 0 11 5.7304688 L 11 8 L 8 8 C 5.8026661 8 4 9.8026661 4 12 L 4 16 L 2 16 L 2 18 L 4 18 L 4 20 C 4 21.093063 4.9069372 22 6 22 L 18 22 C 19.093063 22 20 21.093063 20 20 L 20 18 L 22 18 L 22 16 L 20 16 L 20 12 C 20 9.8026661 18.197334 8 16 8 L 13 8 L 13 5.7285156 A 2 2 0 0 0 14 4 A 2 2 0 0 0 12 2 z M 8 10 L 11 10 L 13 10 L 16 10 C 17.116666 10 18 10.883334 18 12 L 18 20 L 15 20 L 15 18 L 9 18 L 9 20 L 6 20 L 6 12 C 6 10.883334 6.8833339 10 8 10 z M 9.5 13 A 1.5 1.5 0 0 0 8 14.5 A 1.5 1.5 0 0 0 9.5 16 A 1.5 1.5 0 0 0 11 14.5 A 1.5 1.5 0 0 0 9.5 13 z M 14.5 13 A 1.5 1.5 0 0 0 13 14.5 A 1.5 1.5 0 0 0 14.5 16 A 1.5 1.5 0 0 0 16 14.5 A 1.5 1.5 0 0 0 14.5 13 z"
        fill={color}
      />
    </svg>
  );
};

export default BotsIcon;
