import { useCallback, useEffect } from 'react';
import { getOrganizationsSocket } from '../../../../infra/sockets/organization-socket';
import useSocket from '../../../../shared/hooks/use-socket';
import { ImportExcelSocket } from '../typings';
import { importExcelFilesStatusAtom } from '../../../customers/state';
import { useSetAtom } from 'jotai';
import { IMPORT_EXCEL_STORAGE_KEYS } from '../../../../shared/constants/storage';

const useImportExcelSocket = () => {
  const { socket: organisationSocket } = useSocket(getOrganizationsSocket);
  const setImportExcelFilesStatus = useSetAtom(importExcelFilesStatusAtom);

  const importExcelFileListener = useCallback(
    (payload: ImportExcelSocket) => {
      const {
        operationId,
        status,
        additionalData: {
          fileName,
          failedCsvLink,
          successfulRecords,
          totalRecords,
        },
      } = payload;

      try {
        const excelLastSyncedTime = localStorage.getItem(
          IMPORT_EXCEL_STORAGE_KEYS.EXCEL_LAST_SYNCED_TIME
        );
        if (
          typeof excelLastSyncedTime === 'string' &&
          excelLastSyncedTime.length > 0
        ) {
          if (
            new Date().getTime() - new Date(excelLastSyncedTime).getTime() <
            10 * 60 * 1000
          ) {
            return;
          }
          localStorage.setItem(
            IMPORT_EXCEL_STORAGE_KEYS.EXCEL_LAST_SYNCED_TIME,
            new Date().toISOString()
          );
          localStorage.removeItem(
            IMPORT_EXCEL_STORAGE_KEYS.IMPORTED_EXCEL_SHEETS_STATUS
          );
        }
      } catch (error) {
        localStorage.removeItem(
          IMPORT_EXCEL_STORAGE_KEYS.EXCEL_LAST_SYNCED_TIME
        );
      }
      setImportExcelFilesStatus((prev) => {
        const updatedStatuses = (prev || []).map((fileStatus) =>
          fileStatus.operationId === operationId
            ? {
                ...fileStatus,
                status,
                failedCsvLink,
                successfulRecords,
                totalRecords,
              }
            : fileStatus
        );

        const isFileAlreadyPresent = updatedStatuses.some(
          (fileStatus) => fileStatus.operationId === operationId
        );

        if (isFileAlreadyPresent) {
          return updatedStatuses;
        }

        return [
          ...updatedStatuses,
          {
            operationId,
            status,
            fileName,
            failedCsvLink,
            totalRecords,
            successfulRecords,
          },
        ];
      });
    },
    [setImportExcelFilesStatus]
  );

  useEffect(() => {
    organisationSocket.on('operations-status', importExcelFileListener);
    return () => {
      organisationSocket.off('operations-status', importExcelFileListener);
    };
  }, [importExcelFileListener, organisationSocket]);
};

export default useImportExcelSocket;
