import { atomWithReset } from 'jotai/utils';
import {
  SampleValuesFamilyType,
  TemplateAtom,
  TemplateStatus,
} from '../typings/template';
import { atom } from 'jotai';

export const templateAtom = atomWithReset<TemplateAtom>({
  status: TemplateStatus.ALL,
});

export const sampleValuesAtom = atomWithReset<SampleValuesFamilyType[]>([]);

export const autoRetrySettingsModalVisibleAtom = atom<boolean>(false);

export const selectedBroadcastIdAtom = atom<string|undefined>(undefined)