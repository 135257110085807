import { useEffect } from 'react';
import { useCustomNavigate } from '../../../shared/hooks/use-conversation-navigate';

const Dialog360Callback = () => {
  const navigate = useCustomNavigate();

  useEffect(() => {
    const search = window.location.search;
    if (window.opener) {
      if (typeof window.opener.postMessage === 'function') {
        window.opener.postMessage(search);
      }
      if (typeof window.close === 'function') {
        window.close();
      }
    } else {
      navigate({ pathname: '/' }, { replace: true });
    }
  }, [navigate]);

  return null;
};

export default Dialog360Callback;
