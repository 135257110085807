import { FC, SVGProps } from 'react';

const StatusBusyIcon: FC<SVGProps<SVGSVGElement>> = ({ height = 18, width = 18, fill = 'none', ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 18 18"
      width={width}
      height={height}
      fill="none"
      {...props}
    >
      <path
        d="M5.66675 9.83341H12.3334C12.5695 9.83341 12.7674 9.75355 12.9272 9.59383C13.0869 9.43411 13.1667 9.23619 13.1667 9.00008C13.1667 8.76397 13.0869 8.56605 12.9272 8.40633C12.7674 8.24661 12.5695 8.16675 12.3334 8.16675H5.66675C5.43064 8.16675 5.23272 8.24661 5.073 8.40633C4.91328 8.56605 4.83341 8.76397 4.83341 9.00008C4.83341 9.23619 4.91328 9.43411 5.073 9.59383C5.23272 9.75355 5.43064 9.83341 5.66675 9.83341ZM9.00008 17.3334C7.8473 17.3334 6.76397 17.1147 5.75008 16.6772C4.73619 16.2397 3.85425 15.6459 3.10425 14.8959C2.35425 14.1459 1.7605 13.264 1.323 12.2501C0.885498 11.2362 0.666748 10.1529 0.666748 9.00008C0.666748 7.8473 0.885498 6.76397 1.323 5.75008C1.7605 4.73619 2.35425 3.85425 3.10425 3.10425C3.85425 2.35425 4.73619 1.7605 5.75008 1.323C6.76397 0.885498 7.8473 0.666748 9.00008 0.666748C10.1529 0.666748 11.2362 0.885498 12.2501 1.323C13.264 1.7605 14.1459 2.35425 14.8959 3.10425C15.6459 3.85425 16.2397 4.73619 16.6772 5.75008C17.1147 6.76397 17.3334 7.8473 17.3334 9.00008C17.3334 10.1529 17.1147 11.2362 16.6772 12.2501C16.2397 13.264 15.6459 14.1459 14.8959 14.8959C14.1459 15.6459 13.264 16.2397 12.2501 16.6772C11.2362 17.1147 10.1529 17.3334 9.00008 17.3334ZM9.00008 15.6667C10.8612 15.6667 12.4376 15.0209 13.7292 13.7292C15.0209 12.4376 15.6667 10.8612 15.6667 9.00008C15.6667 7.13897 15.0209 5.56258 13.7292 4.27091C12.4376 2.97925 10.8612 2.33341 9.00008 2.33341C7.13897 2.33341 5.56258 2.97925 4.27091 4.27091C2.97925 5.56258 2.33341 7.13897 2.33341 9.00008C2.33341 10.8612 2.97925 12.4376 4.27091 13.7292C5.56258 15.0209 7.13897 15.6667 9.00008 15.6667Z"
        fill="#C21313"
      />
    </svg>
  );
};

export default StatusBusyIcon;
