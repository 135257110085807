import { FC, SVGProps } from 'react';

const GroupIcon: FC<SVGProps<SVGSVGElement> & { size?: number }> = ({
  color,
  size = 24,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={color}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <g xmlns="http://www.w3.org/2000/svg">
        <path d="M 12 4 C 10.066406 4 8.5 5.566406 8.5 7.5 C 8.5 9.433594 10.066406 11 12 11 C 13.933594 11 15.5 9.433594 15.5 7.5 C 15.5 5.566406 13.933594 4 12 4 Z M 12 6 C 12.828125 6 13.5 6.671875 13.5 7.5 C 13.5 8.328125 12.828125 9 12 9 C 11.171875 9 10.5 8.328125 10.5 7.5 C 10.5 6.671875 11.171875 6 12 6 Z M 4.5 7 C 3.117188 7 2 8.117188 2 9.5 C 2 10.882812 3.117188 12 4.5 12 C 5.882812 12 7 10.882812 7 9.5 C 7 8.117188 5.882812 7 4.5 7 Z M 19.5 7 C 18.117188 7 17 8.117188 17 9.5 C 17 10.882812 18.117188 12 19.5 12 C 20.882812 12 22 10.882812 22 9.5 C 22 8.117188 20.882812 7 19.5 7 Z M 4.5 8.722656 C 4.929688 8.722656 5.277344 9.070312 5.277344 9.5 C 5.277344 9.929688 4.929688 10.277344 4.5 10.277344 C 4.070312 10.277344 3.722656 9.929688 3.722656 9.5 C 3.722656 9.070312 4.070312 8.722656 4.5 8.722656 Z M 19.5 8.722656 C 19.929688 8.722656 20.277344 9.070312 20.277344 9.5 C 20.277344 9.929688 19.929688 10.277344 19.5 10.277344 C 19.070312 10.277344 18.722656 9.929688 18.722656 9.5 C 18.722656 9.070312 19.070312 8.722656 19.5 8.722656 Z M 12 13 C 10.523438 13 8.125 13.46875 6.554688 14.398438 C 5.914062 14.15625 5.210938 14 4.5 14 C 2.261719 14 0 15.398438 0 16.933594 L 0 20 L 24 20 L 24 16.933594 C 24 15.398438 21.738281 14 19.5 14 C 18.789062 14 18.085938 14.15625 17.445312 14.398438 C 15.875 13.46875 13.476562 13 12 13 Z M 12 15 C 14.132812 15 16.621094 15.960938 17 16.566406 L 17 18 L 7 18 L 7 16.566406 C 7.378906 15.960938 9.867188 15 12 15 Z M 4.5 16 C 4.699219 16 4.875 16.035156 5.058594 16.0625 C 5.019531 16.203125 5 16.347656 5 16.5 L 5 18 L 2 18 L 2 17.011719 C 2.183594 16.691406 3.199219 16 4.5 16 Z M 19.5 16 C 20.800781 16 21.816406 16.691406 22 17.011719 L 22 18 L 19 18 L 19 16.5 C 19 16.347656 18.980469 16.203125 18.941406 16.0625 C 19.125 16.035156 19.300781 16 19.5 16 Z M 19.5 16 " />
      </g>
    </svg>
  );
};

export default GroupIcon;
