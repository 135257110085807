import { useCallback, useMemo, useRef } from 'react';
import { getSubscribedPlanUseCase } from '../use-cases/get-subscribed-plan.use-case';
import { subscribedPlanAtom } from '../states/subscribed-plan.atom';
import useQuery from '../../../../shared/hooks/useQuery';
import { SubscribedPlan } from '../typings';

export function useSubscribedPlan(autoFetch = true) {
  const {
    requestData: { data: subscribedPlan, loading, error },
    fetchData,
    setData,
  } = useQuery<SubscribedPlan>({
    queryFunction: getSubscribedPlanUseCase,
    requestAtom: subscribedPlanAtom,
    autoFetch,
  });

  const shouldMakePayment = useCallback(() => {
    return subscribedPlan?.canMakeNextPayment;
  }, [subscribedPlan]);

  const askToContactSupport = useMemo(() => {
    return subscribedPlan?.canMakeNextPayment
      ? subscribedPlan.function?.type
      : undefined;
  }, [subscribedPlan]);

  const verifySubscribedPlan = useCallback(async () => {
    if (loading) {
      return Promise.resolve(undefined);
    }

    if (typeof subscribedPlan?.canMakeNextPayment === 'boolean') {
      return subscribedPlan?.canMakeNextPayment;
    }

    try {
      const { data } = await fetchData();
      return data?.canMakeNextPayment || false;
    } catch (error) {
      return false;
    }
  }, [fetchData, loading, subscribedPlan]);

  return {
    subscribedPlan: subscribedPlan,
    verifySubscribedPlan,
    loading,
    askToContactSupport,
    error,
    shouldMakePayment,
    setSubscribedPlan: setData,
  };
}
