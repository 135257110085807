import { useEffect } from 'react';

const updateAfter = 5 * 60 * 1000; // 5 min

const useUpdateBalance = ({
  refreshBalance,
}: {
  refreshBalance: () => void;
}) => {
  useEffect(() => {
    const intervalTimer = setInterval(() => {
      refreshBalance();
    }, updateAfter);

    return () => {
      clearInterval(intervalTimer);
    };
  }, [refreshBalance]);
};

export default useUpdateBalance;
