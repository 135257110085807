import { atom } from 'jotai';
import {
  WabaIntegration,
  WabaIntegrationMember,
  WabaIntegrationMembersPaginationParams,
  WabaIntegrationMembersReducerActions,
  WabaIntegrationPaginationParams,
  WabaIntegrationsActionTypes,
  WabaIntegrationsReducerActions,
  WabaIntegrationMembersActionTypes,
  WabaMetaData,
} from '../typings/waba';
import {
  createPaginatedRequestFamily,
  createRequestAtom,
} from '../utils/request-atom';
import { PaginatedQueryRequest } from '../typings/request';
import ChatCache from '../../modules/home/v1/states/cache';

export const selectedWabaAtom = atom<WabaIntegration | undefined>(undefined);

export const wabaIntegrationsAtomFamily = createPaginatedRequestFamily<
  WabaIntegration,
  WabaIntegrationPaginationParams
>({
  loading: false,
});

export const wabaIntegrationsCountAtom = atom<number | undefined>(undefined);

export const wabaIntegrationsStatusAtom = atom((get) => {
  let wabaIntegrationsStatus: {
    id: string | undefined;
    loading: boolean;
    refreshing: boolean | undefined;
    isFetchingMore: boolean;
  } = {
    id: undefined,
    loading: false,
    refreshing: false,
    isFetchingMore: false,
  };
  for (const id in wabaIntegrationsAtomFamily) {
    if (wabaIntegrationsAtomFamily.hasOwnProperty(id)) {
      const wabaIntegrationsAtom = get(wabaIntegrationsAtomFamily({ id }));
      if (
        wabaIntegrationsAtom.loading ||
        wabaIntegrationsAtom.refreshing ||
        wabaIntegrationsAtom.isFetchingMore
      ) {
        wabaIntegrationsStatus = {
          id,
          loading: wabaIntegrationsAtom.loading,
          refreshing: wabaIntegrationsAtom.refreshing,
          isFetchingMore: wabaIntegrationsAtom.isFetchingMore,
        };
        break;
      }
    }
  }
  return wabaIntegrationsStatus;
});

export const wabaIntegrationsReducer = (
  prev: PaginatedQueryRequest<WabaIntegration, WabaIntegrationPaginationParams>,
  action: WabaIntegrationsReducerActions
): PaginatedQueryRequest<WabaIntegration, WabaIntegrationPaginationParams> => {
  const { type, data: wabaIntegration } = action;
  if (type === WabaIntegrationsActionTypes.UPSERT_INTEGRATION) {
    const wabaIntegrations = [...(prev.data ?? [])];
    const wabaIntegrationIndex = wabaIntegrations.findIndex?.(
      ({ id }) => id === wabaIntegration.id
    );
    if (wabaIntegrationIndex > -1) {
      wabaIntegrations[wabaIntegrationIndex] = {
        ...wabaIntegrations[wabaIntegrationIndex],
        ...wabaIntegration,
      };
      return { ...prev, data: wabaIntegrations };
    } else {
      return {
        ...prev,
        data: wabaIntegrations.concat(wabaIntegration as WabaIntegration),
      };
    }
  } else if (type === WabaIntegrationsActionTypes.BULK_UPSERT_INTEGRATIONS) {
    const wabaIntegrations = [...(prev.data ?? [])];
    wabaIntegration?.forEach?.((wabaIntegration) => {
      const wabaIntegrationIndex = wabaIntegrations.findIndex?.(
        ({ id }) => id === wabaIntegration.id
      );
      if (wabaIntegrationIndex > -1) {
        wabaIntegrations[wabaIntegrationIndex] = {
          ...wabaIntegrations[wabaIntegrationIndex],
          ...wabaIntegration,
        };
      } else {
        wabaIntegrations.push(wabaIntegration as WabaIntegration);
      }
    });
    return { ...prev, data: wabaIntegrations };
  } else if (type === WabaIntegrationsActionTypes.OVERWRITE_INTEGRATIONS) {
    return { ...prev, data: wabaIntegration };
  } else if (
    type === WabaIntegrationsActionTypes.DELETE_INTEGRATION &&
    prev.data?.find?.(({ id }) => id === wabaIntegration)
  ) {
    return {
      ...prev,
      data: prev.data?.filter?.(({ id }) => id !== wabaIntegration),
    };
  } else if (type === WabaIntegrationsActionTypes.RESET_INTEGRATIONS) {
    return {
      ...prev,
      data: undefined,
      fetched: false,
      hasMore: false,
      isFetchingMore: false,
      paginationData: undefined,
      loading: false,
      refreshing: false,
      error: undefined,
    };
  }
  return prev;
};

export const wabaIntegrationMembersStatusAtom = atom((get) => {
  let wabaIntegrationMembersStatus: {
    id: string | undefined;
    loading: boolean;
    refreshing: boolean | undefined;
    isFetchingMore: boolean;
  } = {
    id: undefined,
    loading: false,
    refreshing: false,
    isFetchingMore: false,
  };
  for (const id in wabaIntegrationMembersAtomFamily) {
    if (wabaIntegrationMembersAtomFamily.hasOwnProperty(id)) {
      const wabaIntegrationMembersAtom = get(
        wabaIntegrationMembersAtomFamily({ id })
      );
      if (
        wabaIntegrationMembersAtom.loading ||
        wabaIntegrationMembersAtom.refreshing ||
        wabaIntegrationMembersAtom.isFetchingMore
      ) {
        wabaIntegrationMembersStatus = {
          id,
          loading: wabaIntegrationMembersAtom.loading,
          refreshing: wabaIntegrationMembersAtom.refreshing,
          isFetchingMore: wabaIntegrationMembersAtom.isFetchingMore,
        };
        break;
      }
    }
  }
  return wabaIntegrationMembersStatus;
});

export const wabaIntegrationMembersAtomFamily = createPaginatedRequestFamily<
  WabaIntegrationMember,
  WabaIntegrationMembersPaginationParams
>({
  loading: false,
});

export const wabaIntegrationMembersResetFromUserId = atom(
  null,
  (get, set, data: Array<{ userId: string }>) => {
    const cache = ChatCache.getInstance();
    let integrationsDone: string[] = [];
    data?.forEach?.(({ userId }) => {
      const integrations = Array.from(
        cache.getUserToIntegrationCache(userId) ?? []
      );
      integrations?.forEach?.((integration) => {
        if (integrationsDone.includes(integration)) return;
        set(wabaIntegrationMembersAtomFamily({ id: integration }), {
          data: undefined,
          fetched: false,
          hasMore: false,
          isFetchingMore: false,
          paginationData: undefined,
          loading: false,
          refreshing: false,
          error: undefined,
        });
        integrationsDone.push(integration);
      });
    });
  }
);

export const wabaIntegrationMembersReducer = (
  prev: PaginatedQueryRequest<
    WabaIntegrationMember,
    WabaIntegrationMembersPaginationParams
  >,
  action: WabaIntegrationMembersReducerActions
): PaginatedQueryRequest<
  WabaIntegrationMember,
  WabaIntegrationMembersPaginationParams
> => {
  const { type, data: wabaIntegrationMember } = action;
  if (type === WabaIntegrationMembersActionTypes.UPSERT_INTEGRATION_MEMBER) {
    const wabaIntegrationMembers = [...(prev.data ?? [])];
    const wabaIntegrationMemberIndex = wabaIntegrationMembers.findIndex?.(
      ({ id }) => id === wabaIntegrationMember.id
    );
    if (wabaIntegrationMemberIndex > -1) {
      wabaIntegrationMembers[wabaIntegrationMemberIndex] = {
        ...wabaIntegrationMembers[wabaIntegrationMemberIndex],
        ...wabaIntegrationMember,
      };
      return { ...prev, data: wabaIntegrationMembers };
    } else {
      return {
        ...prev,
        data: wabaIntegrationMembers.concat(
          wabaIntegrationMember as WabaIntegrationMember
        ),
      };
    }
  } else if (
    type === WabaIntegrationMembersActionTypes.BULK_UPSERT_INTEGRATION_MEMBERS
  ) {
    const wabaIntegrationMembers = [...(prev.data ?? [])];
    wabaIntegrationMember?.forEach?.((wabaIntegrationMember) => {
      const wabaIntegrationMemberIndex = wabaIntegrationMembers.findIndex?.(
        ({ id }) => id === wabaIntegrationMember.id
      );
      if (wabaIntegrationMemberIndex > -1) {
        wabaIntegrationMembers[wabaIntegrationMemberIndex] = {
          ...wabaIntegrationMembers[wabaIntegrationMemberIndex],
          ...wabaIntegrationMember,
        };
      } else {
        wabaIntegrationMembers.push(
          wabaIntegrationMember as WabaIntegrationMember
        );
      }
    });
    return { ...prev, data: wabaIntegrationMembers };
  } else if (
    type === WabaIntegrationMembersActionTypes.OVERWRITE_INTEGRATION_MEMBERS
  ) {
    return { ...prev, data: wabaIntegrationMember };
  } else if (
    type === WabaIntegrationMembersActionTypes.DELETE_INTEGRATION_MEMBER &&
    prev.data?.find?.(({ id }) => id === id)
  ) {
    return {
      ...prev,
      data: prev.data?.filter?.(({ id }) => id !== wabaIntegrationMember),
    };
  } else if (
    type === WabaIntegrationMembersActionTypes.RESET_INTEGRATION_MEMBERS
  ) {
    return {
      ...prev,
      data: undefined,
      fetched: false,
      hasMore: false,
      isFetchingMore: false,
      paginationData: undefined,
      loading: false,
      refreshing: false,
      error: undefined,
    };
  }
  return prev;
};

export const wabaIntegrationTypeAtom = createRequestAtom<WabaMetaData>();
