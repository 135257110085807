/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from '@emotion/react';
import { Box, Icon } from '@mui/material';
import { TEXT_COLOR_LIGHT } from '../../typings';
import { QSTypography } from '../../components/atoms';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

interface EmptyDataMessageProps {
  message?: string;
  messageStyles?: SerializedStyles;
  containerStyles?: SerializedStyles;
}

const EmptyDataMessage = ({
  message,
  messageStyles,
  containerStyles,
}: EmptyDataMessageProps) => {
  return (
    <Box
      css={css(
        `
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        column-gap: 8px;
        justify-content: center;
        align-items: center;
        row-gap: 4px;
        padding: 16px;
        color: ${TEXT_COLOR_LIGHT};
      `,
        containerStyles
      )}
    >
      <Icon>
        <ReportProblemIcon />
      </Icon>
      <QSTypography
        css={css(
          `
            margin: 0px;
            font-family: Roboto, sans-serif;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.5;
            text-align: center;
          `,
          messageStyles,
        )}
      >
        {message || 'Something went wrong'}
      </QSTypography>
    </Box>
  );
};

export default EmptyDataMessage;