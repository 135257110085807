import { get, patch, post } from '../../infra/rest';
import { DEFAULT_TEAM } from '../constants';
import {
  GetWabaIntegrationMembersResponse,
  GetWabaIntegrationsResponse,
  WabaChannelStatus,
  WabaChannelType,
  WabaIntegrationAssignee,
  WabaIntegrationMemberStatus,
  WabaIntegrationMembersPaginationParams,
  WabaIntegrationPaginationParams,
  WabaMetaData,
} from '../typings/waba';

export const getWabaMetaData = async () => {
  return get<WabaMetaData>(
    `/v1/organization/integration/waba/metadata`,
    undefined,
    true
  );
};

export const getWabaIntegrations = async (
  paginationParams: WabaIntegrationPaginationParams
): Promise<GetWabaIntegrationsResponse> => {
  const {
    teamId,
    forMemberId,
    sendOnlyAssignedIntegrations,
    sort,
    sortAfter,
    status,
    searchTerm,
  } = paginationParams || {};
  const data = await post<GetWabaIntegrationsResponse>(
    `/v1/organization/waba-integrations`,
    {
      body: JSON.stringify({
        teamId: teamId ?? DEFAULT_TEAM,
        forMemberId: forMemberId ?? null,
        sendOnlyAssignedIntegrations: sendOnlyAssignedIntegrations ?? false,
        status:
          typeof status === 'string' &&
          status.length > 0 &&
          status !== WabaChannelStatus.ALL
            ? status
            : null,
        sort: sort ?? null,
        sortAfter: sortAfter ?? null,
        searchTerm: searchTerm ?? null,
      }),
    },
    true
  );
  let integrationTypes = [
    ...(data.integrationTypes ?? [WabaChannelType.WHATSAPP]),
  ];
  if (integrationTypes.length <= 1) {
    integrationTypes = [];
  } else {
    if (
      integrationTypes.length > 1 &&
      integrationTypes.indexOf(WabaChannelType.ALL) === -1
    ) {
      integrationTypes = [WabaChannelType.ALL, ...integrationTypes];
    }
  }
  return { ...data, integrationTypes };
};

export const getWabaIntegrationMembers = async (
  paginationParams: WabaIntegrationMembersPaginationParams
): Promise<GetWabaIntegrationMembersResponse> => {
  const {
    teamId,
    roleId,
    forIntegrationId,
    removeBotUser,
    sort,
    sortAfter,
    searchTerm,
    nonPaginated,
    sendOnlyAssignedMembers,
    status,
  } = paginationParams || {};
  return post<GetWabaIntegrationMembersResponse>(
    `/v1/organization/waba-integrations/members`,
    {
      body: JSON.stringify({
        teamId: teamId ?? DEFAULT_TEAM,
        roleId: roleId ?? null,
        forIntegrationId: forIntegrationId ?? null,
        removeBotUser:
          typeof removeBotUser === 'boolean' ? removeBotUser : true,
        sort: sort ?? null,
        sortAfter: sortAfter ?? null,
        searchTerm: searchTerm ?? null,
        nonPaginated: nonPaginated ?? false,
        sendOnlyAssignedMembers: sendOnlyAssignedMembers ?? false,
        status: status ?? null,
      }),
    },
    true
  );
};

export const assignWabaIntegrationMembers = async ({
  assignments,
  unassignments,
}: {
  assignments: WabaIntegrationAssignee[];
  unassignments: WabaIntegrationAssignee[];
}): Promise<{ assignedAgentsCountMap: Record<string, number> }> => {
  return post<{ assignedAgentsCountMap: Record<string, number> }>(
    `/v1/organization/waba-integrations/assign`,
    {
      body: JSON.stringify({ assignments, unassignments }),
    },
    true
  );
};

export const activateWabaIntegration = async (
  integrationId: string
): Promise<void> => {
  return patch<void>(
    `/v1/organization/integration/${integrationId}/connect`,
    undefined,
    true
  );
};

export const deactivateWabaIntegration = async (
  integrationId: string
): Promise<void> => {
  return patch<void>(
    `/v1/organization/integration/${integrationId}/disconnect`,
    undefined,
    true
  );
};
