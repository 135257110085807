type KeyValuePairs = Record<string, any>;

export function formatObjectToFormDataKeyValuePairs(
  object: KeyValuePairs,
  parentKey: string = ''
): KeyValuePairs {
  if (Array.isArray(object)) {
    return object.reduce((acc, item, index) => {
      const key = `${parentKey}[${index}]`;
      return {
        ...acc,
        ...formatObjectToFormDataKeyValuePairs(item, key),
      };
    }, {});
  } else if (typeof object === 'object' && object !== null) {
    return Object.entries(object).reduce((acc, [key, value]) => {
      const newKey = parentKey ? `${parentKey}[${key}]` : key;
      return {
        ...acc,
        ...formatObjectToFormDataKeyValuePairs(value, newKey),
      };
    }, {});
  } else {
    return {
      [parentKey]: object,
    };
  }
}
