import { FC, SVGProps } from 'react';

const InboxIcon: FC<SVGProps<SVGSVGElement> & { size?: number }> = ({
  color = '#2d2d2d',
  size = 21,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21 21"
      fill={color}
      width={size}
      height={size}
      {...props}
    >
      <path
        d="M4.66667 18C4.20833 18 3.81597 17.8368 3.48958 17.5104C3.16319 17.184 3 16.7917 3 16.3333V4.66667C3 4.20833 3.16319 3.81597 3.48958 3.48958C3.81597 3.16319 4.20833 3 4.66667 3H16.3333C16.7917 3 17.184 3.16319 17.5104 3.48958C17.8368 3.81597 18 4.20833 18 4.66667V16.3333C18 16.7917 17.8368 17.184 17.5104 17.5104C17.184 17.8368 16.7917 18 16.3333 18H4.66667ZM4.66667 16.3333H16.3333V13.8333H13.8333C13.4167 14.3611 12.9201 14.7708 12.3438 15.0625C11.7674 15.3542 11.1528 15.5 10.5 15.5C9.84722 15.5 9.23264 15.3542 8.65625 15.0625C8.07986 14.7708 7.58333 14.3611 7.16667 13.8333H4.66667V16.3333ZM10.5 13.8333C10.9444 13.8333 11.3542 13.7188 11.7292 13.4896C12.1042 13.2604 12.4097 12.9583 12.6458 12.5833C12.7292 12.4583 12.8333 12.3576 12.9583 12.2813C13.0833 12.2049 13.2222 12.1667 13.375 12.1667H16.3333V4.66667H4.66667V12.1667H7.625C7.77778 12.1667 7.91667 12.2049 8.04167 12.2813C8.16667 12.3576 8.27083 12.4583 8.35417 12.5833C8.59028 12.9583 8.89583 13.2604 9.27083 13.4896C9.64583 13.7188 10.0556 13.8333 10.5 13.8333Z"
        fill={color}
      />
    </svg>
  );
};
export default InboxIcon;
