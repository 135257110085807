import { get, post } from '../../infra/rest';
import {
  RechargeWalletRequest,
  RechargeWalletResponse,
  WalletDetails,
} from '../typings/wallet';

export const getWalletDetails = async (): Promise<WalletDetails> => {
  return get<WalletDetails>(`/v2/wallet`, undefined, true);
};

export const rechargeWallet = async (
  data: RechargeWalletRequest
): Promise<RechargeWalletResponse> => {
  return post<RechargeWalletResponse>(
    `/v1/wallet/payment-link`,
    {
      body: JSON.stringify(data),
    },
    true
  );
};
