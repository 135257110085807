import { FC, SVGProps } from 'react';

const GroupsOutlinedIcon: FC<
  SVGProps<SVGSVGElement> & { size?: number }
> = ({ color = 'currentColor', size = 24, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M 19 3 L 18 5 L 9 8 L 5 8 C 3.9 8 3 8.9 3 10 L 3 14 C 3 15.1 3.9 16 5 16 L 7 16 L 7 21 L 9 21 L 9 16 L 18 19 L 19 21 L 21 21 L 21 13.722656 C 21.595634 13.376387 22 12.738946 22 12 C 22 11.261054 21.595634 10.623613 21 10.277344 L 21 3 L 19 3 z M 19 6.7753906 L 19 17.224609 L 18.632812 17.101562 L 11 14.558594 L 11 9.4414062 L 18.632812 6.8984375 L 19 6.7753906 z M 5 10 L 9 10 L 9 14 L 7 14 L 5 14 L 5 10 z"
        fill={color}
      />
    </svg>
  );
};

export default GroupsOutlinedIcon;
