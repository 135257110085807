import { lazy, memo } from 'react';
import { useOpenTestBroadcastModal } from '../../../../hooks/use-open-test-broadcast-modal';
import { useRole } from '../../../../hooks/use-role';

const LazyCreateOwnAccountModal = lazy(
  () => import('./create-own-account-modal')
);

const LazySendTestBroadcastModal = lazy(
  () => import('./send-test-broadcast-modal')
);

const LazyDemoUserAccountBar = lazy(() => import('./demo-user-account-setup'));

export const CreateOwnAccountModalRenderer = memo(() => {
  const { isDemoUser } = useRole();
  if (!isDemoUser) {
    return null;
  }

  return <LazyCreateOwnAccountModal />;
});

export const DemoAccountUserBarRenderer = memo(() => {
  const { isDemoUser } = useRole();
  if (!isDemoUser) {
    return null;
  }
  return <LazyDemoUserAccountBar />;
});

export const SendTestBroadcastModalRenderer = memo(() => {
  const sendTestBroadcastModal = useOpenTestBroadcastModal();

  if (!sendTestBroadcastModal.open) {
    return null;
  }

  return <LazySendTestBroadcastModal />;
});
