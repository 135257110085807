import { UserDetails } from './../typings/index';
import { get } from '../../../../infra/rest';

let cachePromise: Promise<UserDetails>;
let cacheData: UserDetails;

const getUserDetails = async (refresh?: boolean) => {
  if (!refresh) {
    if (cacheData) {
      return cacheData;
    }

    if (cachePromise) {
      return cachePromise;
    }

    cachePromise = get<UserDetails>(`/v1/user`, {}, true).then((data) => {
      cacheData = data;
      return data;
    });

    return cachePromise;
  }

  return get<UserDetails>(`/v1/user`, {}, true);
};

export default getUserDetails;
