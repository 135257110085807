import { Screens } from './../../shared/typings/screens';
import mixpanel, { Dict as AnalyticsEventOptions } from 'mixpanel-browser';

export const trackEvent = (
  event: string,
  properties?: AnalyticsEventOptions
) => {
  if (properties) {
    // mixpanel.track(event, properties);
    return;
  }

  // mixpanel.track(event);
};

export const trackLoginStart = () => {
  trackEvent('start_login_screen', { from: Screens.LoginFragment });
};

export const trackChatScreenStart = () => {
  trackEvent('start_chat_screen', {});
};

export const trackDemoRequest = (data: { link: string; phone: string }) => {
  const { link, phone } = data;
  trackEvent('onboarding_request_demo', {
    link,
    phone_number: phone,
  });
};

export const trackConversationStart = (data: {
  chatId: string;
  phone: string;
  name: string;
}) => {
  const { chatId, name, phone } = data;
  trackEvent('start_conversation_screen', {
    chat_id: chatId,
    phone_number: phone,
    name,
  });
};

export const trackLoginPhoneFocused = () => {
  trackEvent('login_phone_number_inputbox_focused', {
    from: Screens.LoginFragment,
  });
};

export const trackLoginRequestOtp = () => {
  trackEvent('request_otp_for_login', { from: Screens.LoginFragment });
};

export const trackLoginVerifyOtp = () => {
  trackEvent('verify_otp_for_login', { from: Screens.LoginFragment });
};

export const trackLoginSuccess = () => {
  trackEvent('logged_in_successfully', {
    from: Screens.LoginFragment,
    provider: 'PHONE',
  });
};

export const trackCreateOrg = () => {
  trackEvent('start_create_organization_screen', {
    from: Screens.CreateOrganizationFragment,
  });
};

export const trackCreateOrgInputFocused = () => {
  trackEvent('organization_name_inputbox_focused', {
    from: Screens.CreateOrganizationFragment,
  });
};

export const trackCreateorgSuccess = (data: { orgName: string }) => {
  const orgName = data;

  trackEvent('organization_created', {
    from: Screens.CreateOrganizationFragment,
    org_name: orgName,
  });
};

export const trackStartIntegration = () => {
  trackEvent('start_integration_screen', {});
};

export const trackSelectOrg = () => {
  trackEvent('organization_selected', {
    from: Screens.SelectOrganizationFragment,
  });
};

export const trackCreateOwnAccountClick = () => {
  trackEvent('create_own_account', {});
};

export const trackDemoAccountDialogViewed = () => {
  trackEvent('demo_account_dialog_viewed', {});
};

export const trackResendOtpClick = () => {
  trackEvent('resend_otp', {});
};

export const trackChangeLoginPhoneNumber = () => {
  trackEvent('change_login_phone_number', {});
};

export const trackStartOnboardingNextStepsScreen = () => {
  trackEvent('start_onboarding_next_steps_screen', {});
};

export const trackPlayedOnboardingVideo = () => {
  trackEvent('play_onboarding_video', {});
};

export const trackStartProcessClick = () => {
  trackEvent('start_process_clicked', {});
};

export const trackRequestSupportClick = () => {
  trackEvent('request_support_clicked', {});
};

export const trackExploreDemoClick = () => {
  trackEvent('explore_demo_clicked', {});
};

export const trackJoinOrg = (data: { orgId: string; orgName: string }) => {
  const { orgId, orgName } = data;

  trackEvent('join_organization', {
    from: Screens.SelectOrganizationFragment,
    org_id: orgId,
    org_name: orgName,
  });
};

export const trackOnboardingReqDemo = () => {
  trackEvent('onboarding_request_demo', {
    from: Screens.RequestDemoFragment,
  });
};

export const trackNewChatClick = () => {
  trackEvent('new_chat_clicked', {
    from: Screens.ChatListFragment,
  });
};

export const trackAssignConversation = (data: {
  memberId: string;
  memberPhone: string;
  memberName: string;
}) => {
  const { memberId, memberPhone, memberName } = data;
  trackEvent('conversation_assigned_to', {
    from: Screens.IndividualChatActivity,
    team_member_id: memberId,
    team_member_phone: memberPhone,
    team_member_name: memberName,
  });
};

export const trackUnassignConversation = (data: {
  customerPhone: string;
  customerName: string;
}) => {
  const { customerName, customerPhone } = data;

  trackEvent('conversation_unassigned', {
    from: Screens.IndividualChatActivity,
    customer_phone_number: customerPhone,
    customer_name: customerName,
  });
};

export const trackOpenConversation = (data: {
  chatId: string;
  name: string;
  phone: string;
}) => {
  const { chatId, name, phone } = data;
  trackEvent('open_conversation', {
    from: Screens.IndividualChatActivity,
    chat_id: chatId,
    name,
    phone_number: phone,
  });
};

export const trackOpenCustomerDetails = (data: {
  customerPhone: string;
  customerName: string;
  chatId: string;
}) => {
  const { customerName, customerPhone, chatId } = data;

  trackEvent('view_customer_profile', {
    from: Screens.IndividualChatActivity,
    customer_phone_number: customerPhone,
    customer_name: customerName,
    chat_id: chatId,
  });
};

export const trackStartCustomerDetails = () => {
  trackEvent('start_customer_profile_screen', {
    from: Screens.IndividualChatActivity,
  });
};

export const trackChatInputEmojiClick = () => {
  trackEvent('chatbox_emoji_clicked', {
    from: Screens.IndividualChatActivity,
  });
};

export const trackChatInputAttachmentClick = () => {
  trackEvent('chatbox_attachment_clicked', {
    from: Screens.IndividualChatActivity,
  });
};

export const trackHeaderTagsClick = () => {
  trackEvent('menu_tag_clicked', {
    from: Screens.IndividualChatActivity,
  });
};

export const trackHeaderTagsCreated = () => {
  trackEvent('tag_created', {
    from: Screens.IndividualChatActivity,
  });
};

export const trackTagsApplied = () => {
  trackEvent('tag_applied_to_chat', {
    from: Screens.IndividualChatActivity,
  });
};

export const trackAddToBroadcastMenuClick = () => {
  trackEvent('menu_add_to_broadcast', {
    from: Screens.IndividualChatActivity,
  });
};

// FIXME: couldn't add as its has to come from MINI_APP
export const trackAddedToBroadcast = () => {
  trackEvent('added_to_broadcast_list', {
    from: Screens.IndividualChatActivity,
  });
};

export const trackMenuFilterClick = () => {
  trackEvent('menu_filter', {
    from: Screens.ChatListFragment,
  });
};

export const trackSendMessage = (data: {
  chatId: string;
  name: string;
  customerPhone: string;
}) => {
  const { chatId, name, customerPhone } = data;

  trackEvent('conversation_message_sent', {
    from: Screens.IndividualChatActivity,
    customer_phone_number: customerPhone,
    name: name,
    chat_id: chatId,
  });
};

// TODO: add screen name
export const trackSidebarOpen = () => {
  trackEvent('open_drawer', {});
};

export const trackSidebarClose = () => {
  trackEvent('close_drawer', {});
};

// TODO: add screen name
export const trackLogout = () => {
  trackEvent('logout', {});
};
