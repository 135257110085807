import { Box } from '@mui/material';
import { TEXT_COLOR_LIGHT, VARIABLES_BORDER_COLOR } from '../../../../typings';
import { QSTypography } from '../../../atoms';
import { FC, RefObject } from 'react';

interface VariableOptionProps {
  name: string;
  id: string;
  textInputRef: RefObject<HTMLInputElement>;
  onClick?: (val: string) => void;
  handleFetchSampleValue?: (id: string, name: string) => void;
  isBroadcast?: boolean;
}

export const VariableOption: FC<VariableOptionProps> = ({
  name,
  id,
  textInputRef,
  onClick,
  isBroadcast,
  handleFetchSampleValue,
}) => {
  const handleOnClick = () => {
    if (isBroadcast) handleFetchSampleValue?.(id, name);
    try {
      const ref = textInputRef.current;
      if (!ref) {
        return;
      }
      let cursorPos = ref?.selectionStart || 0;
      let textBeforeCursorPosition = ref?.value.substring(0, cursorPos);
      let textAfterCursorPosition = ref?.value.substring(
        cursorPos,
        ref.value?.length
      );

      onClick?.(
        textBeforeCursorPosition + `{{${name}}}` + textAfterCursorPosition
      );
      ref.value =
        textBeforeCursorPosition + `{{${name}}}` + textAfterCursorPosition;
      cursorPos = cursorPos + name.length + 4;
      ref.focus();
      ref.setSelectionRange(cursorPos, cursorPos);
    } catch (error) {
      // Do nothing
    }
  };

  return (
    <Box
      key={id + name}
      sx={{
        border: `1px dashed ${VARIABLES_BORDER_COLOR}`,
        padding: '6px 12px',
        width: 'max-content',
        height: 'min-content',
        borderRadius: '14px',
        marginTop: '12px',
        cursor: 'pointer',
      }}
      onClick={handleOnClick}
    >
      <QSTypography
        sx={{
          fontSize: '10px',
          fontWeight: '400',
          color: TEXT_COLOR_LIGHT,
          width: 'inherit',
        }}
      >
        {name}
      </QSTypography>
    </Box>
  );
};
