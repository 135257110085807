import { get } from '../../../../../infra/rest';
import { RefreshNextState } from '../typings';

const getRefreshNextState = async () => {
  return get<RefreshNextState>(
    '/v1/demo/refresh-next-state',
    {},
    true
  );
};

export default getRefreshNextState;
