import { useProfile } from './use-profile';
import { useUserPermissions } from './use-permission';
import { useCallback } from 'react';

export const useConversationPermissions = () => {
  const {
    canReadConversation,
    canWriteConversation,
    canReadAssignedConversation,
    canReadUnassignedConversation,
    canWriteAssignedConversation,
    canWriteUnassignedConversation,
    canReadTeamAssignedConversation,
  } = useUserPermissions();

  const { id } = useProfile() ?? {};

  const getConversationPermissions = useCallback(
    (conversationId: string, assignedUserId: string | null) => {
      let canRead = false;
      let canWrite = false;

      if (checkResource(canReadConversation, conversationId)) {
        canRead = true;
      } else if (checkResource(canReadAssignedConversation, conversationId)) {
        if (assignedUserId) {
          id === assignedUserId && (canRead = true);
        } else {
          canRead = true;
        }
      } else if (checkResource(canReadUnassignedConversation, conversationId)) {
        !assignedUserId && (canRead = true);
      }

      if (checkResource(canWriteConversation, conversationId)) {
        canWrite = true;
      } else if (
        assignedUserId &&
        checkResource(canWriteAssignedConversation, conversationId)
      ) {
        id === assignedUserId && (canWrite = true);
      } else if (
        checkResource(canWriteUnassignedConversation, conversationId)
      ) {
        !assignedUserId && (canWrite = true);
      }

      if (checkResource(canReadTeamAssignedConversation, conversationId)) {
        canRead = true;
      }

      return {
        canRead,
        canWrite,
      };
    },
    [
      canReadConversation,
      canWriteConversation,
      canReadAssignedConversation,
      canReadUnassignedConversation,
      canWriteAssignedConversation,
      canWriteUnassignedConversation,
      id,
    ]
  );

  return { getConversationPermissions };
};

export const useIntegrationPermission = () => {
  const { canReadIntegration, canConnectIntegration, canDisconnectIntegration } = useUserPermissions();
  return {
    canReadIntegration: (integrationId: string) => {
      return checkResource(canReadIntegration, integrationId)
    },
    canConnectIntegration: (integrationId: string) => {
      return checkResource(canConnectIntegration, integrationId)
    },
    canDisconnectIntegration: (integrationId: string) => {
      return checkResource(canDisconnectIntegration, integrationId)
    },
  };
};

export const useMemberPermissions = () => {
  const { canAddMembers, canReadMembers, canRemoveMembers, canUpdateMembers } =
    useUserPermissions();

  const getMemberPermissions = useCallback(
    (memberId: string) => {
      let canRead = false;
      let canAdd = false;
      let canUpdate = false;
      let canRemove = false;

      if (checkResource(canReadMembers, memberId)) {
        canRead = true;
      }

      if (checkResource(canAddMembers, memberId)) {
        canAdd = true;
      }

      if (checkResource(canUpdateMembers, memberId)) {
        canUpdate = true;
      }

      if (checkResource(canRemoveMembers, memberId)) {
        canRemove = true;
      }

      return {
        canRead,
        canAdd,
        canUpdate,
        canRemove,
      };
    },
    [canAddMembers, canReadMembers, canRemoveMembers, canUpdateMembers]
  );

  return { getMemberPermissions };
};

const checkResource = (
  permission: {
    hasAccess: boolean;
    resources: string[];
  },
  id: string
) => {
  return permission.hasAccess
    ? permission.resources.includes(id) || permission.resources.includes('*')
    : false;
};

export const useCustomerPhonePermissions = () => {
  const { canReadCustomerPhone } = useUserPermissions();

  const getPhonePermissions = useCallback(
    (customerId: string) => {
      return {
        canRead: checkResource(canReadCustomerPhone, customerId),
      };
    },
    [canReadCustomerPhone]
  );

  return { getPhonePermissions };
};
