import { atom } from 'jotai';

import { createRequestAtom, createPaginatedRequestAtom } from '../../../shared/utils/request-atom';
import { CustomerSegment, CustomerSegmentFilterContact, GetCustomerSegment, GetCustomerSegmentsMetaData, GetCustomerSegmentsPaginationData, ImportExcelFileStatus } from '../typings';

export const customerSegmentsMetaDataAtom =
  createRequestAtom<GetCustomerSegmentsMetaData>();

export const getAllCustomerSegmentsAtom = createPaginatedRequestAtom<
  CustomerSegment,
  GetCustomerSegmentsPaginationData
>({ loading: false });

export const getCustomerSegmentAtom = createRequestAtom<
  GetCustomerSegment | undefined
>();

export const activeCustomerSegmentAtom = atom<CustomerSegment | null>(null);

export const getAllCustomerSegmentContactsAtom = createPaginatedRequestAtom<
  CustomerSegmentFilterContact,
  GetCustomerSegmentsPaginationData
>({ loading: false });

export const importExcelFilesStatusAtom = atom<ImportExcelFileStatus[] | null>(null);

export const importCustomersNotificationAtom = atom<boolean>(false);

export const isExcelFileUploadLoadingAtom = atom<boolean>(false);

export const importCustomersModalVisibleAtom = atom<boolean>(false);

export const excelUploadStatusModalVisibleAtom = atom<boolean>(false);
