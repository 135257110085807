const dbName = 'DT_ORG';
const storeName = 'organizations';

function openDatabase(): Promise<IDBDatabase> {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, 1);

    request.onupgradeneeded = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName, { keyPath: 'orgId' });
      }
    };

    request.onsuccess = (event) => {
      resolve((event.target as IDBOpenDBRequest).result);
    };

    request.onerror = (event) => {
      reject((event.target as IDBOpenDBRequest).error);
    };
  });
}

async function deleteAllOrganizations(): Promise<void> {
  const db = await openDatabase();

  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, 'readwrite');
    const store = transaction.objectStore(storeName);

    const deleteRequest = store.clear(); // This clears all records in the store

    deleteRequest.onsuccess = () => {
      resolve();
    };

    deleteRequest.onerror = (event) => {
      console.error('Error deleting all organizations:', event);
      reject((event.target as IDBRequest).error);
    };
  });
}

async function saveSelectedOrganization(orgId: string): Promise<void> {
  const db = await openDatabase();

  return new Promise((resolve, reject) => {
    deleteAllOrganizations()
      .then(() => {
        const transaction = db.transaction(storeName, 'readwrite');
        const store = transaction.objectStore(storeName);
        const request = store.put({ orgId }); // Add or update organization

        request.onsuccess = () => {
          resolve();
        };

        request.onerror = (event) => {
          console.error('Error saving organization:', event);
          reject((event.target as IDBRequest).error);
        };
      })
      .catch((error) => {
        console.error('Failed to delete all organizations:', error);
      });
  });
}

export default saveSelectedOrganization;
