import { useAtom } from 'jotai';
import { fileTypesAtom } from '../states/file-types';
import { rootScope } from '../states/user';
import { get } from '../../infra/rest';
import { FileTypes } from '../typings/file-types';

export const useFileTypeAtom = () => {
  return useAtom(fileTypesAtom, rootScope);
};

export const useFileType = () => {
  return useFileTypeAtom()[0];
};

export const useSetFileType = () => useFileTypeAtom()[1];

export const fetchFileTypes = async (): Promise<FileTypes> => {
  return get<FileTypes>(
    `/v1/organization/integration/allowed-file-types`,
    undefined,
    true
  );
};
