/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box, IconButton, Link } from '@mui/material';
import QSModal from '../../../../shared/components/molecules/modal';
import { QSButton, QSTypography } from '../../../../shared/components/atoms';
import { Close } from '@mui/icons-material';
import { ReactComponent as InformationIcon } from '../../../../../src/shared/icons/Information.svg';
import { ReactComponent as WhatsappIcon } from '../../../../../src/shared/icons/WhatsappIcon.svg';
import { ReactComponent as KeyIcon } from '../../../../../src/shared/icons/KeyIcon.svg';
import { VALUE_FIRST_UPGRADE_BOOK_SLOT } from '../../constants';
import { Dispatch, SetStateAction } from 'react';
import { useSetAtom } from 'jotai';
import { showValueFirstUpgradeModalAtom } from '../../../home/v1/states/value-first-modal';
import { useProfile } from '../../../../shared/hooks/use-profile';
import { useSelectedOrganization } from '../../../../shared/hooks/use-organizations';

interface ValueFirstUpgradeConfirmationProps {
  showValueFirstUpgradeConfirmationModal: boolean;
  setShowValueFirstUpgradeConfirmationModal: Dispatch<SetStateAction<boolean>>;
}

const ValueFirstUpgradeConfirmation = ({
  showValueFirstUpgradeConfirmationModal,
  setShowValueFirstUpgradeConfirmationModal,
}: ValueFirstUpgradeConfirmationProps) => {
  const userDetails = useProfile();
  const currentOrg = useSelectedOrganization();
  const handleClose = () => setShowValueFirstUpgradeConfirmationModal(false);
  const setShowValueFirstUpgradeModal = useSetAtom(
    showValueFirstUpgradeModalAtom
  );

  return (
    <QSModal
      open={showValueFirstUpgradeConfirmationModal}
      onClose={handleClose}
    >
      <Box
        css={css`
          width: 100%;
          height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        <Box
          css={css`
            width: 450px;
            border-radius: 8px;
            gap: 16px;
            background: #fbfbfc;
            display: flex;
            flex-direction: column;
            row-gap: 16px;
          `}
        >
          <Box
            css={css`
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 8px 12px 8px 20px;
              border-bottom: 1px solid #e0e0e9;
            `}
          >
            <QSTypography
              css={css`
                font-size: 16px;
                font-weight: 700;
              `}
            >
              Please note
            </QSTypography>
            <IconButton onClick={handleClose}>
              <Close
                css={css`
                  color: #7a7a7a;
                  font-size: 24px;
                `}
              />
            </IconButton>
          </Box>
          <Box
            css={css`
              display: flex;
              flex-direction: column;
              row-gap: 20px;
              padding: 0px 20px;
              gap: 20px;
            `}
          >
            <Box
              css={css`
                padding: 12px;
                display: flex;
                gap: 8px;
                border: 1px solid #e0e0e9;
                border-radius: 12px;
                background: #fff;
              `}
            >
              <InformationIcon fontSize={20} />
              <Box
                css={css`
                  display: flex;
                  flex-direction: column;
                  row-gap: 4px;
                `}
              >
                <QSTypography
                  css={css`
                    font-size: 14px;
                    font-weight: 500;
                  `}
                >
                  Before booking your update slot
                </QSTypography>
                <QSTypography
                  css={css`
                    font-size: 14px;
                    font-weight: 500;
                  `}
                >
                  Please ensure you have the following information ready:
                </QSTypography>
              </Box>
            </Box>
            <Box
              css={css`
                display: flex;
                flex-direction: column;
                gap: 16px;
              `}
            >
              <Box
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: 12px;
                `}
              >
                <Box
                  css={css`
                    display: flex;
                    gap: 8px;
                  `}
                >
                  <WhatsappIcon />
                  <QSTypography
                    css={css`
                      font-size: 14px;
                      font-weight: 600;
                    `}
                  >
                    WhatsApp Business API (WABA) Number
                  </QSTypography>
                </Box>
                <ul
                  css={css`
                    margin: 0px;
                    padding-left: 32px;
                  `}
                >
                  <li
                    css={css`
                      font-size: 14px;
                      font-weight: 400;
                    `}
                  >
                    {' '}
                    Ensure you have the SIM card for this number handy
                  </li>
                  <li
                    css={css`
                      font-size: 14px;
                      font-weight: 400;
                    `}
                  >
                    {' '}
                    An OTP will be sent to this number for verification
                  </li>
                </ul>
              </Box>
              <Box
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: 12px;
                `}
              >
                <Box
                  css={css`
                    display: flex;
                    gap: 8px;
                  `}
                >
                  <KeyIcon />
                  <QSTypography
                    css={css`
                      font-size: 14px;
                      font-weight: 600;
                    `}
                  >
                    Facebook Business Manager account
                  </QSTypography>
                </Box>
                <ul
                  css={css`
                    margin: 0px;
                    padding-left: 32px;
                  `}
                >
                  <li
                    css={css`
                      font-size: 14px;
                      font-weight: 400;
                    `}
                  >
                    Your facebook username and password
                  </li>
                </ul>
              </Box>
            </Box>
            <QSTypography
              css={css`
                font-size: 14px;
                font-weight: 400;
                color: #7a7a7a;
              `}
            >
              Our agent will guide you through the entire update process once
              you've booked your slot.
            </QSTypography>
          </Box>
          <Box
            css={css`
              padding: 8px 16px;
              display: flex;
              justify-content: flex-end;
              gap: 8px;
              border-top: 1px solid #e0e0e9;
            `}
          >
            <QSButton
              variant="outlined"
              css={css`
                font-size: 14px;
                font-weight: 600;
                color: #2d2d2d;
                border: 1px solid #e0e0e9;

                &:hover {
                  border: 1px solid #c9c9d1;
                  background: rgba(224, 224, 233, 0.3);
                }
              `}
              onClick={handleClose}
            >
              Not ready yet
            </QSButton>
            <Link
              href={`${VALUE_FIRST_UPGRADE_BOOK_SLOT}a1=+${currentOrg?.wabaPhoneNumber}&a2=${userDetails?.phone}&name=${userDetails?.name}&email=${userDetails?.email}`}
              target="_blank"
              rel="noreferrer"
            >
              <QSButton
                css={css`
                  font-size: 14px;
                  font-weight: 600;
                `}
                onClick={() => {
                  localStorage.setItem(
                    'VF_PROCEED_TO_BOOK_CLICKED_TIME',
                    String(new Date().getTime())
                  );
                  setShowValueFirstUpgradeModal(false);
                  setShowValueFirstUpgradeConfirmationModal(false);
                }}
              >
                Proceed to book
              </QSButton>
            </Link>
          </Box>
        </Box>
      </Box>
    </QSModal>
  );
};

export default ValueFirstUpgradeConfirmation;
