/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, useCallback } from 'react';
import Box from '@mui/material/Box';
import { GREEN } from '../../../typings';
import { WalletDetails, currency_symbols } from '../../../typings/wallet';
import useQuery from '../../../hooks/useQuery';
import { walletDetailsAtom } from '../../../states/wallet';
import { getWalletDetails } from '../../../requests/wallet-balance';
import Loader from '../loader';
import useUpdateBalance from './v1/hooks/use-update-balance';
import { useSetAtom } from 'jotai';
import { rechargeWalletAtom } from '../../../../modules/home/v1/states/recharge-wallet';
import { QSButton, QSTypography } from '../../atoms';
import { openPopup } from '../../../utils/popup';
import requestConfig from '../../../config/request';
import { getFirebaseToken } from '../../../../infra/auth/firebase-auth';
import { shouldHideDTDetails } from '../../../../modules/login/v1/utility';

const Wallet: FC<{ showExpandedView: boolean }> = ({ showExpandedView }) => {
  const {
    requestData: { data: walletData, loading },
    fetchData,
  } = useQuery<WalletDetails | undefined>({
    requestAtom: walletDetailsAtom,
    queryFunction: getWalletDetails,
    cacheData: true,
  });

  const setRechargeModal = useSetAtom(rechargeWalletAtom);
  const hideDTDetails = shouldHideDTDetails();
  const refreshBalance = useCallback(() => fetchData(true), [fetchData]);

  useUpdateBalance({ refreshBalance });

  const handleOnClickRecharge = async () => {
    if (!walletData || loading) return;
    if (walletData.generatePaymentLink) {
      const token = await getFirebaseToken();

      openPopup(
        `${requestConfig.dtCallbackUrl}/wallet?token=${token}`,
        '_self'
      );
    } else window.open(walletData.rechargeLink, '_blank');
  };

  if (!walletData) {
    return null;
  }

  return (
    <Box
      css={css`
        margin: 0 0 0 10px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        flex-wrap: nowrap;
        align-items: center;
        align-self: stretch;
        overflow: hidden;
        white-space: nowrap;
      `}
    >
      {loading ? (
        <Loader
          size={18}
          containerProps={{
            sx: {
              marginLeft: 'auto',
            },
          }}
        />
      ) : (
        <Box
          css={css`
            width: 100%;
            display: flex;
            align-items: center;
            column-gap: 16px;
          `}
        >
          <QSTypography
            css={css`
              font-size: 13px;
              font-weight: 500;
            `}
          >
            {currency_symbols[walletData?.currencyCode] ||
              walletData?.currencyCode}
            {walletData?.balance?.toFixed(2)}
          </QSTypography>
          {!hideDTDetails ? (
            <QSButton
              variant="contained"
              css={css`
                color: ${GREEN};
                background-color: transparent;
                border: 1px solid ${GREEN};
                border-radius: 4px;
                box-shadow: none;
                outline: none;
                cursor: pointer;
                height: 30px;
                padding: 4px 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                column-gap: 12px;

                &:hover,
                &:focus,
                &:active {
                  color: #ffffff;
                  background-color: ${GREEN};
                  box-shadow: none;
                  outline: none;
                }
              `}
              disabled={loading || !showExpandedView}
              onMouseDown={(event) => {
                // Note: This is added to prevent nested button ripple effect
                event.preventDefault();
                event.stopPropagation();
              }}
              onClick={handleOnClickRecharge}
              TouchRippleProps={{
                style: {
                  color: '#25254984',
                },
              }}
            >
              <QSTypography
                css={css`
                  font-size: 13px;
                  font-weight: 600;
                `}
              >
                Recharge
              </QSTypography>
            </QSButton>
          ) : null}
        </Box>
      )}
    </Box>
  );
};

export default Wallet;
