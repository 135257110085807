import { FC, SVGProps } from 'react';

const AllChats: FC<SVGProps<SVGSVGElement> & { size?: number }> = ({ fill = '#7A7A7A', height = 24, width = 24, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 17"
      width={width}
      height={height}
      fill="none"
      {...props}
    >
      <path d="M4.83464 13.9993C4.59852 13.9993 4.40061 13.9195 4.24089 13.7598C4.08116 13.6 4.0013 13.4021 4.0013 13.166V11.4993H14.8346V3.99935H16.5013C16.7374 3.99935 16.9353 4.07921 17.0951 4.23893C17.2548 4.39865 17.3346 4.59657 17.3346 4.83268V15.3118C17.3346 15.6868 17.1645 15.9473 16.8242 16.0931C16.4839 16.2389 16.1819 16.1799 15.918 15.916L14.0013 13.9993H4.83464ZM4.0013 9.83268L2.08464 11.7493C1.82075 12.0132 1.51866 12.0723 1.17839 11.9264C0.838108 11.7806 0.667969 11.5202 0.667969 11.1452V1.49935C0.667969 1.26324 0.74783 1.06532 0.907552 0.905599C1.06727 0.745877 1.26519 0.666016 1.5013 0.666016H12.3346C12.5707 0.666016 12.7687 0.745877 12.9284 0.905599C13.0881 1.06532 13.168 1.26324 13.168 1.49935V8.99935C13.168 9.23546 13.0881 9.43338 12.9284 9.5931C12.7687 9.75282 12.5707 9.83268 12.3346 9.83268H4.0013ZM11.5013 8.16602V2.33268H2.33464V8.16602H11.5013Z" fill={fill} />
    </svg>
  );
};

export default AllChats;
