export const generateRedirectUrl = ({
  baseUrl,
  redirectUrl,
  encodeBase64 = false,
}: {
  baseUrl: string;
  redirectUrl: string;
  encodeBase64?: boolean;
}) => {
  let urlToRedirectTo = redirectUrl;
  if (encodeBase64) {
    urlToRedirectTo = btoa(redirectUrl);
  }
  return `${baseUrl}/${urlToRedirectTo}`;
};
