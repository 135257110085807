import { FC, SVGProps } from 'react';

const RequestsIcon: FC<SVGProps<SVGSVGElement> & { size?: number }> = ({
  color = '#2d2d2d',
  size = 24,
  ...props
}) => {
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      viewBox="1 0 24 24"
      {...props}
      width={size}
      height={size}
    >
      <path d="M17 12c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm1.65 7.35L16.5 17.2V14h1v2.79l1.85 1.85-.7.71zM18 3h-3.18C14.4 1.84 13.3 1 12 1s-2.4.84-2.82 2H6c-1.1 0-2 .9-2 2v15c0 1.1.9 2 2 2h6.11c-.59-.57-1.07-1.25-1.42-2H6V5h2v3h8V5h2v5.08c.71.1 1.38.31 2 .6V5c0-1.1-.9-2-2-2zm-6 2c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"></path>
    </svg>
  );
};

export default RequestsIcon;
