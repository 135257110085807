const copyToClipboard = (data: string): Promise<void> => {
  const input = document.createElement('textarea');
  input.innerHTML = data;
  document.body.appendChild(input);
  input.select();
  const result = document.execCommand('copy');
  document.body.removeChild(input);
  return result ? Promise.resolve() : Promise.reject();
};

export default copyToClipboard;
