import { Alert, Slide } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useRef, useMemo, useEffect, useCallback, FC } from 'react';
import useNotifications from '../../../hooks/use-notifications';
import { notificationAtomFamily } from '../../../states/notifications';
import SpecialWarning from '../special-warning';
import Paper from '@mui/material/Paper';

const Notification: FC<{ id: string }> = ({ id }) => {
  const mountRef = useRef(false);

  const notificationAtom = useMemo(() => {
    return notificationAtomFamily({ id });
  }, [id]);

  const notificaiton = useAtomValue(notificationAtom);
  const {
    open,
    message,
    secondaryMessage,
    autoHideDuration = 2000,
    type = 'success',
  } = notificaiton;
  const { hideNotification, removeNotification } = useNotifications();

  const onExitAnimation = useCallback(() => {
    return removeNotification({ id });
  }, [id, removeNotification]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    const wasMounted = mountRef.current;
    if (!wasMounted) {
      timer = setTimeout(function () {
        hideNotification({ id });
      }, autoHideDuration);
      mountRef.current = true;
    }

    return () => {
      if (timer && wasMounted) {
        clearTimeout(timer);
      }
    };
  }, [hideNotification, id, autoHideDuration]);

  return (
    <Slide
      direction="left"
      in={open}
      mountOnEnter
      unmountOnExit
      style={{ marginBottom: '12px' }}
      onExited={onExitAnimation}
    >
      {type === 'special-warning' ? (
        <Paper
          elevation={3}
          style={{
            backgroundColor: 'transparent',
          }}
        >
          <SpecialWarning
            message={message}
            secondaryMessage={secondaryMessage}
          />
        </Paper>
      ) : (
        <Alert severity={type}>{message}</Alert>
      )}
    </Slide>
  );
};

export default Notification;
