import { get } from '../../infra/rest';
import { Organization } from '../../modules/login/v1/typings';
import { TrainingLinkDataType } from '../components/organisms/header';

export const getOrganizations = async ({
  options = {},
  authRequired = true,
}: {
  options?: RequestInit;
  authRequired?: boolean;
} = {}) => {
  const data = await get<{
    organizations: Array<Organization & { orgId: string }>;
  }>('/v3/auth/organizations', options, authRequired);

  return data?.organizations.map(
    (org) => ({ ...org, id: org.orgId } as Organization)
  );
};

export const getTrainingLink = async () => {
  return get<TrainingLinkDataType>(
    '/v1/organization/training-link',
    undefined,
    true,
  );
};
