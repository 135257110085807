import { get } from '../../../../infra/rest';
import requestConfig from '../../../../shared/config/request';
import { ChannelPartnerPublicConfig } from '../../../../shared/typings/request';

export const getChannelPartnerPublicDetails = async () => {
  return get<ChannelPartnerPublicConfig>(
    `${requestConfig.baseUrl}/v1/channel-partner/configurations`,
    {},
    false,
    true,
    true
  );
};
