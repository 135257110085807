import { FC, SVGProps } from 'react';

const AnalyticsIcon: FC<
  SVGProps<SVGSVGElement> & { size?: number }
> = ({ fill = 'currentColor', size = 24, ...props }) => {
  return (
    <svg viewBox="0 -960 960 960" width={size} height={size} {...props} fill="none">
      <path
        d="M320-280q17 0 28.5-11.5T360-320v-200q0-17-11.5-28.5T320-560q-17 0-28.5 11.5T280-520v200q0 17 11.5 28.5T320-280Zm160 0q17 0 28.5-11.5T520-320v-320q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640v320q0 17 11.5 28.5T480-280Zm160 0q17 0 28.5-11.5T680-320v-80q0-17-11.5-28.5T640-440q-17 0-28.5 11.5T600-400v80q0 17 11.5 28.5T640-280ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"
        fill={fill}
      />
    </svg>
  );
};

export default AnalyticsIcon;
