/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  DialogActions,
  IconButton,
  Stack,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import NotificationIcon from './assets/notification.svg';

const ForDefaultPermission = ({
  onClose,
  handleOpen,
}: {
  onClose: () => void;
  handleOpen: () => void;
}) => {
  return (
    <div
      role="dialog"
      css={css`
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: absolute;
        right: 22px;
        z-index: 999;
        bottom: 20px;
        width: 480px;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 8px;
        border: 1px solid #e0e0e9;
        background: #fff;
        box-shadow: 0px 24px 56px -4px rgba(88, 92, 95, 0.16);
      `}
    >
      <DialogActions>
        <IconButton
          onClick={onClose}
          style={{ position: 'absolute', right: 5, top: 5 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogActions>

      <DialogContent style={{ textAlign: 'left', padding: '0px 20px 20px' }}>
        <Box display={'flex'}>
          <div
            css={css`
              background: rgba(239, 108, 0, 0.08);
              height: 40px;
              width: 40px;
              padding: 4px;
              margin: 2px;
              margin-right: 12px;
              border-radius: 50%;
            `}
          >
            <img src={NotificationIcon} css={css``} />
          </div>
          <div>
            <Typography
              variant="h6"
              gutterBottom
              css={css`
                color: #2d2d2d;
                font-size: 16px;
                font-weight: 500;
              `}
            >
              Turn on push notifications
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              css={css`
                color: #7a7a7a;
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 15px;
              `}
            >
              Stay updated with all chat activities and DoubleTick alerts on
              your desktop.
            </Typography>
            <Button
              variant="contained"
              color="success"
              css={css`
                height: 36px;
                border-radius: 4px;
                background: #3f906d;
                margin-right: 12px;
              `}
              onClick={handleOpen}
            >
              Allow push notifications
            </Button>

            <Button
              variant="text"
              css={css`
                height: 36px;
              `}
              onClick={onClose}
            >
              I'll do it later
            </Button>
          </div>
        </Box>
      </DialogContent>
    </div>
  );
};

export default ForDefaultPermission;
