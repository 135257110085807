const dateLocale = 'en-US';
export const timeString = {
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  PM: 'pm',
  AM: 'am',
  AGO: ' ago',
  JUST_NOW: 'just now',
  MINUTE: 'minute',
  SECOND: 'second',
  HOUR: 'hour',
  DAY: 'day',
};

export const getDate = (timestamp: number, options?: any) => {
  return new Date(timestamp).toLocaleDateString(
    dateLocale,
    options || {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    }
  );
};

export const getHourMinutes = (timestamp: number) => {
  return new Date(timestamp)
    .toLocaleTimeString(dateLocale, {
      hour12: true,
      hour: '2-digit',
      minute: '2-digit',
    })
    .replace('PM', timeString.PM)
    .replace('AM', timeString.AM);
};

export const getTimeFromTimestamp = (timestamp?: number) => {
  if (!timestamp) {
    return;
  }

  return getHourMinutes(timestamp);
};

const secondsInMilli = 1000;
const minutesInMilli = secondsInMilli * 60;
const hoursInMilli = minutesInMilli * 60;
const daysInMilli = hoursInMilli * 24;

const getTime = (time: number, label: string) => {
  return time + ' ' + label + (time > 1 ? 's' : '');
};

const timeAgo = (time: number, label: string) => {
  return getTime(time, label) + timeString.AGO;
};

// is only valid when elapsed day is 1 or 0;
const isYesterday = (timestamp: number) => {
  const today = new Date().getDay();
  const yesterday = new Date(timestamp).getDay();
  return yesterday !== today;
};

export const getTimeDiffJson = (timestamp: number, isFuture?: boolean) => {
  let difference = (Date.now() - timestamp) * (isFuture ? -1 : 1);

  const elapsedDays = Math.floor(difference / daysInMilli);
  difference = difference - elapsedDays * daysInMilli;

  const elapsedHours = Math.floor(difference / hoursInMilli);
  difference = difference - elapsedHours * hoursInMilli;

  const elapsedMinutes = Math.floor(difference / minutesInMilli);
  difference = difference - elapsedMinutes * minutesInMilli;

  const elapsedSeconds = Math.floor(difference / secondsInMilli);
  difference = difference - elapsedSeconds * secondsInMilli;

  return {
    elapsedDays,
    elapsedHours,
    elapsedMinutes,
    elapsedSeconds,
    difference,
  };
};

export const getFormattedTime = (timestamp: number) => {
  let { elapsedDays } = getTimeDiffJson(timestamp);

  if (elapsedDays <= 1) {
    if (isYesterday(timestamp)) {
      return timeString.YESTERDAY;
    }
    return getHourMinutes(timestamp);
  }

  return getDate(timestamp);
};

export const getDateAndTime = (timeStamp?: number) => {
  if (!timeStamp) {
    return '';
  }
  return getDate(timeStamp) + ' at ' + getHourMinutes(timeStamp);
};

export const getElapsedTime = (timeStamp: number) => {
  const {
    elapsedDays,
    elapsedHours,
    elapsedMinutes,
    // elapsedSeconds
  } = getTimeDiffJson(timeStamp);

  if (elapsedDays === 0) {
    if (elapsedHours === 0) {
      if (elapsedMinutes === 0) {
        return timeString.JUST_NOW;
        // if (elapsedSeconds === 0) {
        // }
        // return timeAgo(elapsedSeconds, timeString.SECOND);
      }
      return timeAgo(elapsedMinutes, timeString.MINUTE);
    } else {
      if (isYesterday(timeStamp)) {
        return `${timeString.YESTERDAY}, ${getHourMinutes(timeStamp)}`;
      }
      return `${timeString.TODAY}, ${getHourMinutes(timeStamp)}`;
    }
  }

  if (elapsedDays <= 365) {
    return [getDate(timeStamp).split(',')[0], getHourMinutes(timeStamp)].join(
      ', '
    );
  }

  return getDateAndTime(timeStamp);
};

export const getPendingTime = (timestamp: number) => {
  const now = Date.now();
  if (timestamp < now) {
    return 'Invalid';
  }

  const { elapsedDays, elapsedHours, elapsedMinutes, elapsedSeconds } =
    getTimeDiffJson(timestamp, true);

  if (elapsedDays) {
    return getTime(elapsedDays, timeString.DAY);
  }

  if (elapsedHours) {
    return getTime(elapsedHours, timeString.HOUR);
  }

  if (elapsedMinutes) {
    return getTime(elapsedMinutes, timeString.MINUTE);
  }

  if (elapsedSeconds) {
    return getTime(elapsedSeconds, timeString.SECOND);
  }

  return timeString.JUST_NOW;
};
