import { get, patch, post } from '../../../infra/rest';
import {
  Integration,
  IntegrationDetails,
  IntegrationStatus,
  IntegrationType,
  PermissionAPIResponse,
} from '../typings';

export const getAllIntegrations = async (): Promise<Integration[]> => {
  const integrations = await get<Integration[]>(
    `/v2/organization/integration`,
    undefined,
    true
  );
  return (integrations ?? [])
    .filter(
      ({ type }) =>
        [
          IntegrationType.WHATSAPP_BUSINESS,
          IntegrationType.WHATSAPP_CLOUD,
        ].indexOf(type) === -1
    )
    .map((integration) => ({
      ...integration,
      integrationSlug:
        integration.type === IntegrationType.QUICKSELL
          ? 'quicksell'
          : integration.type === IntegrationType.OPENAI
          ? 'open-ai'
          : undefined,
    }));
};

export const getIntegrationDetails = async (
  integrationId: string
): Promise<IntegrationDetails> => {
  const integration = await get<IntegrationDetails>(
    `/v1/organization/integration/${integrationId}`,
    undefined,
    true
  );
  return {
    ...integration,
    integrationSlug:
      integration.type === IntegrationType.QUICKSELL
        ? 'quicksell'
        : integration.type === IntegrationType.OPENAI
        ? 'open-ai'
        : undefined,
  };
};

export const updateIntegration = async (
  integrationSlug: string,
  apiKey: string
): Promise<IntegrationDetails> => {
  return post<IntegrationDetails>(
    `/v1/organization/integration/${integrationSlug}`,
    {
      body: JSON.stringify({
        apiKey: apiKey,
      }),
    },
    true
  );
};

export const connectIntegration = async (
  integrationId: string
): Promise<{ status: IntegrationStatus }> => {
  return patch<{ status: IntegrationStatus }>(
    `/v1/organization/integration/${integrationId}/connect`,
    undefined,
    true
  );
};

export const disconnectIntegration = async (
  integrationId: string
): Promise<{ status: IntegrationStatus }> => {
  return patch<{ status: IntegrationStatus }>(
    `/v1/organization/integration/${integrationId}/disconnect`,
    undefined,
    true
  );
};

export const getZohoCRMPermission =
  async (): Promise<PermissionAPIResponse> => {
    return get<PermissionAPIResponse>(
      `/v1/zoho-crm/permission`,
      undefined,
      true
    );
  };
