import { atom } from 'jotai';
import { DialogContentData } from '../typings';

export interface DemoAccount {
  open: boolean;
}

export const demoAccountAtom = atom<DemoAccount>({
  open: false,
});

export const demoDialogContentAtom = atom<DialogContentData | null>(null);
