import { useEffect } from 'react';
const handler = (event: BeforeUnloadEvent) => {
  event.preventDefault();
  event.returnValue = '';
  return 'Are you sure you want to reload/close? Unsaved changes might be lost.';
};

const useBotChangeHandler = (isBotDirty: boolean): void => {
  useEffect(() => {
    if (isBotDirty) {
      window.addEventListener('beforeunload', handler);
    } else {
      window.removeEventListener('beforeunload', handler);
    }
    return () => window.removeEventListener('beforeunload', handler);
  }, [isBotDirty]);
};

export default useBotChangeHandler;
