/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { ButtonBase, TooltipProps } from '@mui/material';
import { FC, SVGProps, MouseEvent, useCallback } from 'react';
import { useCustomNavigate } from '../../../hooks/use-conversation-navigate';
import {
  trackDemoAccountDialogViewed,
  trackEvent,
} from '../../../../infra/analytics/utils';
import { RoutePages } from '../../../constants/routes';
import LockIcon from '../../../icons/LockIcon';
import { GREEN } from '../../../typings';
import { Screens } from '../../../typings/screens';
import QSTooltip from '../tooltip';
import { useSetAtom } from 'jotai';
import { demoAccountAtom } from '../../../modules/demo-account/v1/states/demo-account';
import { openPopup } from '../../../utils/popup';

const SidebarMenuItem: FC<{
  Icon: FC<
    SVGProps<SVGSVGElement> & {
      size?: number | undefined;
    }
  >;
  hasAccess?: boolean;
  path?: string;
  title: TooltipProps['title'];
  showExpandedView: boolean;
  onClick?: (event: MouseEvent) => void;
  style?: SerializedStyles;
  component?: () => void;
  analyticsEvent?: string;
  screenName?: RoutePages;
  hideRipple?: boolean;
}> = ({
  Icon,
  title,
  path,
  hasAccess,
  onClick,
  showExpandedView = false,
  style,
  component,
  analyticsEvent,
  screenName,
  hideRipple,
}) => {
  const navigate = useCustomNavigate();
  const openOwnAccountModal = useSetAtom(demoAccountAtom);
  const getCurrentPage = () => {
    if (!path || !screenName) return false;
    if (window.location.pathname === '/' && path === '/') {
      return true;
    }
    if (path.includes(screenName) && window.location.pathname.includes(path)) {
      return true;
    }
  };

  const color = onClick ? '#2d2d2d' : getCurrentPage() ? GREEN : '#2d2d2d';
  const isLocked = typeof hasAccess !== 'undefined' && !hasAccess;

  const openCreateOwnAccountModal = useCallback(() => {
    openOwnAccountModal({ open: true });
    trackDemoAccountDialogViewed();
  }, [openOwnAccountModal]);

  const lockIcon = isLocked ? (
    <LockIcon
      css={css({
        marginLeft: 'auto',
        ...(!showExpandedView
          ? {
              position: 'absolute',
              right: '10px',
              top: '5px',
              height: '15px',
              width: '15px',
              background: 'rgb(224, 224, 224)',
              padding: '2px',
              borderRadius: '50%',
              border: '1px solid #cdcdcd',
            }
          : {}),
      })}
      height={18}
      width={18}
      fill={'#333333'}
    />
  ) : null;

  let content =
    typeof component === 'function' ? (
      component()
    ) : (
      <h2
        css={css(css`
          flex: 1 1 0;
          align-self: center;
          font-size: 14px;
          margin: 0 0 0 10px;
          color: ${color};
          font-weight: 500;
          overflow: hidden;
          white-space: nowrap;
        `)}
      >
        {title}
      </h2>
    );

  return (
    <QSTooltip
      styles={{
        tooltip: {
          marginLeft: '6px !important',
        },
      }}
      title={!showExpandedView ? title : ''}
      arrow
      placement="right"
    >
      <ButtonBase<'div'>
        css={css(
          css`
            width: 100%;
            padding: 8px 14px 8px 13px;
            font-weight: 600;
            text-align: left;
            justify-content: flex-start;
            align-items: center;
            position: relative;
            cursor: ${(!isLocked && !hideRipple) ? 'pointer' : 'default'};
            &:not(:last-of-type) {
              margin-bottom: 2px;
            }
            ${hideRipple ? '' : `
              &:hover {
                background: #ebebebde;
              }
            `}
          `,
          style
        )}
        onClick={(event) => {
          if (analyticsEvent)
            trackEvent(analyticsEvent, {
              from: Screens.NavigationFragment,
              hasAccess: !!hasAccess,
            });

          if (isLocked) {
            openCreateOwnAccountModal();
            return;
          }

          if (path) {
            if (
              event.ctrlKey ||
              event.shiftKey ||
              event.metaKey || // apple
              (event.button && event.button === 1) // middle click, >IE9 + everyone else
            ) {
              openPopup(path, '_blank');
              return;
            } else {
              navigate({ pathname: path });
            }
          } else {
            onClick?.(event);
          }
        }}
        disableRipple={!showExpandedView || hideRipple}
        component="div"
      >
        <>
          <Icon
            css={css`
              flex: 0 0 auto;
              transition: all 280ms ease-in-out;
            `}
            height={20}
            width={25}
            color={color}
            fill={color}
          />
          {content}
          {lockIcon}
        </>
      </ButtonBase>
    </QSTooltip>
  );
};

export default SidebarMenuItem;
