import { FC, SVGProps } from 'react';

const StatusOfflineIcon: FC<SVGProps<SVGSVGElement>> = ({ height = 12, width = 12, fill = 'none', ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 12 12"
      width={width}
      height={height}
      fill="none"
      {...props}
    >
      <rect width="12" height="12" rx="6" fill="#C21313" />
    </svg>
  );
};

export default StatusOfflineIcon;
