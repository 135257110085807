import { atom } from 'jotai';
import { Organization } from '../../modules/login/v1/typings';
import { GetRequireDemo } from '../typings/request';
import { NextScreenState } from '../typings/screens';
import { createRequestAtom } from '../utils/request-atom';

interface OrganizationAtom {
  firstTimeSetup: boolean;
  organisations: Organization[];
  chooseOrganization: boolean;

  selectOrg: Organization['orgId'];
  loading?: boolean;
}

export interface SendTestBroadcastModal {
  open: boolean;
}

export interface LoginUserDetailsData {
  countryCode: string;
  countrySymbol: string;
  phone: string;
  isOnWhatsapp: boolean;
}

export const authAtom = atom({
  isAuthReady: false,
  isCheckingAuth: true,
  isOrgSelected: false,
  isIntegrationEnabled: false,
  isPermissionsReady: false,
  makePayment: false,
});

export const requireDemoAtom = createRequestAtom<GetRequireDemo>();

export const sendTestBroadcastModalAtom = atom<SendTestBroadcastModal>({
  open: false,
});

export const nextScreenStateAtom = atom<NextScreenState | null>(null);
export const isDismissibleStateAtom = atom<boolean>(false);

export const organizationAtom = atom<OrganizationAtom | undefined>(undefined);

export const selectedOrganizationAtom = atom<Organization | null>(null);

export const showLogoutModalAtom = atom(false);

export enum LoginStep {
  PHONE = 'phone',
  OTP = 'otp',
  ORGANISATION = 'organisation',
  NEXT_STEP = 'next-step',
  EMAIL_VERIFICATION = 'email-verification',
}

export enum SignupStep {
  GET_STARTED = 'get-started',
}

export enum SigninStep {
  CREATE_ACCOUNT = 'create-account',
  EMAIL_VERIFICATION = 'email-verification',
}
