export const deserializeTagsFromQueryParams = (
  queryParams: URLSearchParams
) => {
  let deserializedTags = [];
  try {
    deserializedTags =
      queryParams.has('tags') &&
      (queryParams.get('tags') as string).trim() !== ''
        ? JSON.parse(queryParams.get('tags') as string)
        : [];
  } catch (e) {
    deserializedTags = [];
  }

  return Array.isArray(deserializedTags)
    ? (deserializedTags as string[]).filter(Boolean)
    : [];
};

export const deserializeTagIdsFromQueryParams = (
  queryParams: URLSearchParams
) => {
  let deserializedTagIds = [];
  try {
    deserializedTagIds =
      queryParams.has('tagIds') &&
      (queryParams.get('tagIds') as string).trim() !== ''
        ? JSON.parse(queryParams.get('tagIds') as string)
        : [];
  } catch (e) {
    deserializedTagIds = [];
  }

  return Array.isArray(deserializedTagIds)
    ? (deserializedTagIds as string[]).filter(Boolean)
    : [];
};
