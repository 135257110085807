import { FC, SVGProps } from 'react';

const GroupIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="18px"
      viewBox="0 0 18 12"
      version="1.1"
      {...props}
    >
      <g
        id="Group-Management"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Android-Copy-13"
          transform="translate(-25.000000, -113.000000)"
          fill={props.fill || '#FFFFFF'}
          fillRule="nonzero"
        >
          <path
            d="M34,113 C32.5502525,113 31.375,114.175253 31.375,115.625 C31.375,117.074747 32.5502525,118.25 34,118.25 C35.4497475,118.25 36.625,117.074747 36.625,115.625 C36.625,114.175253 35.4497475,113 34,113 Z M28.375,115.25 C27.3394661,115.25 26.5,116.089466 26.5,117.125 C26.5,118.160534 27.3394661,119 28.375,119 C29.4105339,119 30.25,118.160534 30.25,117.125 C30.25,116.089466 29.4105339,115.25 28.375,115.25 Z M39.625,115.25 C38.5894661,115.25 37.75,116.089466 37.75,117.125 C37.75,118.160534 38.5894661,119 39.625,119 C40.6605339,119 41.5,118.160534 41.5,117.125 C41.5,116.089466 40.6605339,115.25 39.625,115.25 Z M34,119.75 C32.248,119.75 28.75,120.62975 28.75,122.375 L28.75,125 L39.25,125 L39.25,122.375 C39.25,120.62975 35.752,119.75 34,119.75 Z M27.9238281,120.539551 C26.7380781,120.72255 25,121.541809 25,122.528808 L25,125 L27.25,125 L27.25,122.375 C27.25,121.65425 27.5090781,121.049551 27.9238281,120.539551 Z M40.0761715,120.539551 C40.4909222,121.049551 40.75,121.65425 40.75,122.375 L40.75,125 L43,125 L43,122.528808 C43,121.541809 41.2619215,120.72255 40.0761715,120.539551 Z"
            id="Shape"
          />
        </g>
      </g>
    </svg>
  );
};

export default GroupIcon;
