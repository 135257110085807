import { post } from '../../infra/rest';

export const sendSetupToken = async (token: string) => {
  return post(
    '/v1/organization/notification/setup',
    {
      body: JSON.stringify({ token }),
    },
    true
  );
};
