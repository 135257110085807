import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// A custom theme for this app
const theme = createTheme({
  palette: {
    background: {
      default: '#FFFFFF',
    },
    error: {
      main: red.A400,
    },
    primary: {
      main: '#3F906D',
    },
    text: {
      primary: '#2d2d2d',
      secondary: '#8484A8',
    },
  },
  typography: {
    fontFamily: ['inter', 'sans-serif'].join(','),
    button: {
      textTransform: 'none',
    },
  },
});

export default theme;
