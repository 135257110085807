import { FC, SVGProps } from 'react';

const StatusOnLeaveIcon: FC<SVGProps<SVGSVGElement>> = ({ height = 12, width = 13, fill = 'none', ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 13 12"
      width={width}
      height={height}
      fill="none"
      {...props}
    >
      <path
        d="M11.6334 11.5332L7.86672 7.76657L8.80005 6.83324L12.5667 10.5999C12.6889 10.7221 12.75 10.8777 12.75 11.0666C12.75 11.2555 12.6889 11.411 12.5667 11.5332C12.4445 11.6555 12.2889 11.7166 12.1 11.7166C11.9112 11.7166 11.7556 11.6555 11.6334 11.5332ZM4.08338 10.4499C3.77227 10.761 3.40838 10.9082 2.99172 10.8916C2.57505 10.8749 2.2556 10.6943 2.03338 10.3499C1.36672 9.31657 1.01394 8.19713 0.975049 6.99157C0.93616 5.78602 1.21116 4.6499 1.80005 3.58324C1.83338 3.96102 1.92783 4.38602 2.08338 4.85824C2.23894 5.33046 2.45283 5.82768 2.72505 6.3499C2.99727 6.87213 3.32227 7.40824 3.70005 7.95824C4.07783 8.50824 4.49449 9.0499 4.95005 9.58324L4.08338 10.4499ZM5.86672 8.66657C5.33338 8.13324 4.86672 7.55268 4.46672 6.9249C4.06672 6.29713 3.75283 5.68879 3.52505 5.0999C3.29727 4.51102 3.16949 3.9749 3.14172 3.49157C3.11394 3.00824 3.21672 2.6499 3.45005 2.41657C3.68338 2.17213 4.04172 2.06102 4.52505 2.08324C5.00838 2.10546 5.54727 2.23046 6.14172 2.45824C6.73616 2.68602 7.34727 3.00268 7.97505 3.40824C8.60283 3.81379 9.18338 4.28324 9.71672 4.81657L5.86672 8.66657ZM11.3667 1.01657C11.7112 1.23879 11.8945 1.55546 11.9167 1.96657C11.9389 2.37768 11.7945 2.73879 11.4834 3.0499L10.6334 3.8999C10.1112 3.44435 9.57505 3.03324 9.02505 2.66657C8.47505 2.2999 7.94172 1.97768 7.42505 1.6999C6.90838 1.42213 6.41394 1.20268 5.94172 1.04157C5.46949 0.88046 5.04449 0.783238 4.66672 0.749905C5.72227 0.20546 6.85005 -0.04454 8.05005 -9.55057e-05C9.25005 0.0443489 10.3556 0.383238 11.3667 1.01657Z"
        fill="#4285F4"
      />
    </svg>
  );
};

export default StatusOnLeaveIcon;
