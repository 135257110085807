/** @jsxImportSource @emotion/react */
import Box from '@mui/material/Box';
import { FC } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import QSTypography from '../typography';
import QSButton from '../button';
import { css } from '@emotion/react';
import { getUserChannelData } from '../../../../modules/login/v1/utility';

const SpecialWarning: FC<{
  message: string;
  secondaryMessage?: string;
}> = ({ message, secondaryMessage }) => {
  const channelPartnerDetails = getUserChannelData();
  const contactNumber = channelPartnerDetails?.supportNumber || '918356849474';

  return (
    <Box
      css={css`
        max-width: 500px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        row-gap: 8px;
        column-gap: 16px;
        padding: 16px;
        border-radius: 12px;
        background-color: #f5e4e5;

        @media (min-width: 0px) and (max-width: 359px) {
          flex-wrap: wrap;
        }
      `}
    >
      <Box
        css={css`
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          column-gap: 12px;
          flex: 1 1 auto;
        `}
      >
        <Box
          css={css`
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-top: 3px;
          `}
        >
          <ErrorOutlineIcon
            css={css`
              height: 18px;
              min-height: 18px;
              width: 18px;
              min-width: 18px;
              fill: #bd1a1a;
            `}
            fill="#BD1A1A"
          />
        </Box>
        <Box
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            row-gap: 8px;
            column-gap: 8px;
          `}
        >
          <QSTypography
            css={css`
              font-size: 14px;
              font-weight: 600;
              word-wrap: break-word;
              word-break: break-word;
            `}
          >
            {message}
          </QSTypography>
          {secondaryMessage && (
            <QSTypography
              css={css`
                color: #585858;
                font-size: 12px;
                font-weight: 400;
                word-wrap: break-word;
                word-break: break-word;
              `}
            >
              {secondaryMessage}
            </QSTypography>
          )}
        </Box>
      </Box>
      <Box
        css={css`
          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex: 1 0 auto;

          @media (min-width: 0px) and (max-width: 359px) {
            justify-content: flex-start;
            padding-left: 18px;
          }
        `}
      >
        <QSButton
          variant="text"
          css={css`
            padding: 8px 12px;
            color: inherit;
            background-color: transparent;
            border: none;
            outline: none;
            box-shadow: none;

            &:hover {
              color: inherit;
              background-color: transparent;
              border: none;
              outline: none;
              box-shadow: none;
            }
          `}
          TouchRippleProps={{
            style: {
              color: '#25254984',
            },
          }}
          onClick={() => {
            window.open(
              `https://wa.me/${contactNumber}?text=${encodeURIComponent(
                'I want to extend my waba number limit'
              )}`,
              '_blank'
            );
          }}
        >
          <QSTypography
            css={css`
              color: #2d2d2d;
              font-size: 14px;
              font-weight: 600;
              text-decoration: underline;
              text-underline-offset: 4px;
              word-wrap: break-word;
              word-break: break-word;
            `}
          >
            Contact Support
          </QSTypography>
        </QSButton>
      </Box>
    </Box>
  );
};

export default SpecialWarning;
