import { userProfileAtom } from '../states/user';
import { rootScope } from '../states/user';
import { useAtom } from 'jotai';

export const useProfileAtom = () => {
  return useAtom(userProfileAtom, rootScope);
};
export const useProfile = () => {
  return useProfileAtom()[0];
};

export const useSetProfile = () => useProfileAtom()[1];
