import { rootScope } from '../states/user';
import { useAtom } from 'jotai';
import { sendTestBroadcastModalAtom } from '../states/login';

export const useOpenTestBroadcastModalAtom = () => {
  return useAtom(sendTestBroadcastModalAtom, rootScope);
};
export const useOpenTestBroadcastModal = () => {
  return useOpenTestBroadcastModalAtom()[0];
};

export const useSetOpenTestBroadcastModal = () =>
  useOpenTestBroadcastModalAtom()[1];
