import { TemplateCardDataType } from '../../modules/home/v1/typings/message-types';
import { TemplateData } from '../../modules/template-list/typings';

export enum TemplateCategory {
  ACCOUNT_UPDATE = 'ACCOUNT_UPDATE',
  PAYMENT_UPDATE = 'PAYMENT_UPDATE',
  PERSONAL_FINANCE_UPDATE = 'PERSONAL_FINANCE_UPDATE',
  SHIPPING_UPDATE = 'SHIPPING_UPDATE',
  RESERVATION_UPDATE = 'RESERVATION_UPDATE',
  ISSUE_RESOLUTION = 'ISSUE_RESOLUTION',
  APPOINTMENT_UPDATE = 'APPOINTMENT_UPDATE',
  TRANSPORTATION_UPDATE = 'TRANSPORTATION_UPDATE',
  TICKET_UPDATE = 'TICKET_UPDATE',
  ALERT_UPDATE = 'ALERT_UPDATE',
  AUTO_REPLY = 'AUTO_REPLY',
  TRANSACTIONAL = 'TRANSACTIONAL',
  MARKETING = 'MARKETING',
  OTP = 'OTP',
}

export enum TemplateReducerActionTypes {
  STATUS = 'status',
}

export interface TemplateAtom {
  [TemplateReducerActionTypes.STATUS]: TemplateStatus;
}

export enum TemplateStatus {
  ALL = 'ALL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  NEEDS_CLARIFICATION = 'NEEDS_CLARIFICATION',
}

export const TemplateStatusOptions = [
  {
    name: 'All',
    value: TemplateStatus.ALL,
  },
  {
    name: 'Approved',
    value: TemplateStatus.APPROVED,
  },
  {
    name: 'Pending',
    value: TemplateStatus.PENDING,
  },
  {
    name: 'Rejected',
    value: TemplateStatus.REJECTED,
  },
  {
    name: 'Needs Clarification',
    value: TemplateStatus.NEEDS_CLARIFICATION,
  },
];

export enum TemplateType {
  ALL = 'ALL',
  PREDEFINED = 'PREDEFINED',
  CUSTOM = 'CUSTOM',
}

export interface SampleValuesFamilyType {
  id: string;
  name: string;
  sampleValue: SampleValue;
}
export interface SampleValue {
  sample: string[];
  totalCustomerCount: number;
  customerWithGivenFieldCount: number;
}

interface StatusAction {
  type: TemplateReducerActionTypes.STATUS;
  data: TemplateStatus;
}

export type TemplateReducerAction = StatusAction;

export enum TemplateButtonType {
  QUICK_REPLY = 'QUICK_REPLY',
  URL = 'URL',
  PHONE_NUMBER = 'PHONE_NUMBER',
  FLOW = 'FLOW',
}

export enum TemplateComponentFormat {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  DOCUMENT = 'DOCUMENT',
  VIDEO = 'VIDEO',
  LOCATION = 'LOCATION',
}

export enum TemplateComponentTypes {
  HEADER = 'HEADER',
  BODY = 'BODY',
  FOOTER = 'FOOTER',
  BUTTON = 'BUTTON',
  CARD = 'CARD',
  CAROUSEL = 'CAROUSEL',
}

export type VariablesType = 'header' | 'body' | 'button' | 'card';

export type CardVariablesFormat = {
  cardIndex: number;
  components: Array<{
    type: TemplateComponentTypes;
    parameters: Array<Record<string, string | File>>;
  }>;
};

export interface TemplateURLButton {
  type: TemplateButtonType.URL;
  url: string;
  example?: string;
  text: string;
}

export interface TemplatePhoneButton {
  type: TemplateButtonType.PHONE_NUMBER;
  phoneNumber: string;
  text: string;
}

export interface TemplateReplyButton {
  type: TemplateButtonType.QUICK_REPLY;
  text: string;
}

export interface TemplateFlowButton {
  type: TemplateButtonType.FLOW;
  text: string;
}

export type TemplateButtonTypes =
  | TemplateURLButton
  | TemplatePhoneButton
  | TemplateReplyButton
  | TemplateFlowButton;

export interface TemplateButtonComponent {
  type: TemplateComponentTypes.BUTTON;
  buttons: Array<TemplateButtonTypes>;
}
export type TemplateCardWithComp = {
  components?: Array<TemplateData | CardTemplateComponentItemProps>;
}[];
export interface TemplateCardsComponent {
  type: TemplateComponentTypes.CARD | TemplateComponentTypes.CAROUSEL;
  cards: TemplateCardDataType[]; //| TemplateCardWithComp;
}
export interface TemplateTextHeaderComponent {
  format: TemplateComponentFormat.TEXT;
  text: string;
  example?: Record<string, string>;
}

export interface TemplateMediaHeaderComponent {
  format:
    | TemplateComponentFormat.IMAGE
    | TemplateComponentFormat.VIDEO
    | TemplateComponentFormat.DOCUMENT;
  example: {
    link: string;
    previewlink?: string | File;
  };
  link?: string;
  fileName?: string;
}

export type TemplateHeaderComponent = {
  type: TemplateComponentTypes.HEADER;
} & (TemplateTextHeaderComponent | TemplateMediaHeaderComponent);

export interface TemplateBodyComponent {
  type: TemplateComponentTypes.BODY;
  text: string;
  example?: Record<string, string>;
}

export type CardTemplateComponentItemProps =
  | TemplateHeaderComponent
  | TemplateBodyComponent
  | TemplateButtonComponent;

export interface TemplateFooterComponent {
  type: TemplateComponentTypes.FOOTER;
  text: string;
}

export type TemplateComponent =
  | TemplateHeaderComponent
  | TemplateBodyComponent
  | TemplateFooterComponent
  | TemplateButtonComponent
  | TemplateCardsComponent;

export type TemplateComponentsArray = Array<TemplateComponent>;

export interface Template {
  id: number;
  remoteId: string;
  name: string;
  components: TemplateComponentsArray;
  language: string;
  status: TemplateStatus;
  category: TemplateCategory;
  type: TemplateType;
  rejectedReason?: string;
  dateCreated: number;
  dateUpdated: number;
  integrationId: string;
  dateDeleted: number | null;
  createdBy: string;
  createdByUser: {
    id: string;
    name: string;
  };
  editDetails: {
    editable: boolean;
    bots: Array<{
      botId: string;
      botName: string;
      botStatus: 'ACTIVE' | 'INACTIVE';
    }>;
    groups: Array<{
      groupName: string;
      groupId: string;
      chatMessageId: string;
    }>;
    chats: Array<{
      customerName: string;
      chatMessageId: string;
    }>;
  };
}

export interface TempaltePaginationData {
  limit: number;
  offset: number;
}

export interface QuickReply {
  id: string;
  shortcut: string;
  message: string;
}

export interface UserCustomTextType {
  nameKey: string;
  value: string;
}

export interface TemplateMessageErrorType {
  totalCount: number;
  erroredCount: number;
  flaggedCustomFields: string[];
  flaggedCustomFieldTexts: UserCustomTextType[];
}
export interface GetRedirectParamsApiResponse {
  wabaNumber: string;
  customerNumber: string;
}
