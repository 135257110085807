import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';
import { LoginStep, SigninStep } from '../../../../shared/states/login';
import { getCountryCodeFromLocalStorage, getCountryFromCountryCode } from '../../../../shared/utils/countries-mapper';
import { ReceiveTestMessage, UserSignupDetails } from '../typings';
import { createRequestAtom } from '../../../../shared/utils/request-atom';

export const phoneNumberAtom = atomWithReset({
  country: getCountryFromCountryCode(getCountryCodeFromLocalStorage())!,
  phone: '',
});

export const errorAtom = atom('');

export const verifyOtpTokenAtom = atom('');

export const userSignupDetailsAtom = atomWithReset<UserSignupDetails>({
  firstName: '',
  lastName: '',
  orgName: '',
  designation: '',
  websiteUrl: 'https://',
  isWebsiteRequired: true,
});

export const LoginStepAtom = atom<LoginStep>(LoginStep.PHONE);

export const preselectedOrganizationAtom = atom<string>('');

export const signInStepAtom = atom<SigninStep>(SigninStep.CREATE_ACCOUNT);

export const testBroadcastAtom = createRequestAtom<ReceiveTestMessage>();
