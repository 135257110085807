import { io, SocketOptions, ManagerOptions, Socket } from 'socket.io-client';
import requestConfig from '../../shared/config/request';
import { getFirebaseToken } from '../auth/firebase-auth';
import { SocketStatus } from '../../shared/typings';

export const socketOptions: Partial<SocketOptions | ManagerOptions> = {
  withCredentials: true,
  transports: ['websocket'],
  upgrade: true,
  rememberUpgrade: true,
  forceNew: true,
  secure: true,
  closeOnBeforeunload: true,
  reconnection: true,
  reconnectionAttempts: 3,
  reconnectionDelay: 2000,
  reconnectionDelayMax: 6000,
  timeout: 30000,
  auth: async (cb) => {
    cb({ token: await getFirebaseToken() });
  },
};

export const SOCKET_RETRY_COUNTER = 5;

export let newMessageSocket: Socket;

export const messageStatusSockets: Record<string, Socket> = {};

export const getNewMessageSocket = (): Socket => {
  if (newMessageSocket) {
    return newMessageSocket;
  }
  newMessageSocket = io(
    `${requestConfig.socketUrl}/conversations`,
    socketOptions
  );

  newMessageSocket.on('connect', () => {
    document.dispatchEvent(new CustomEvent('conversation-socket-connect'));
  });
  newMessageSocket.on('disconnect', (reason) => {
    // newMessageSocket.off('new-message');
    // newMessageSocket.off('is-done');
    // newMessageSocket.off('mark-read');
    // newMessageSocket.off('chat-sla-status');
    newMessageSocket.off('privacy-update');
    newMessageSocket.off('chat-filters-add-chat');

    // newMessageSocket.off('chat-filter-counts')
  });
  return newMessageSocket;
};

export const getMessageStatusesSocket = (chatId: string): Socket => {
  if (messageStatusSockets[chatId]) {
    return messageStatusSockets[chatId];
  }
  messageStatusSockets[chatId] = io(
    `${requestConfig.socketUrl}/chats?chatId=${chatId}`,
    {
      ...socketOptions,
      query: { chatId },
    }
  );
  let connectionCounter = 0;
  const socket = messageStatusSockets[chatId];
  socket.on('connect', () => {
    connectionCounter++;
    document.dispatchEvent(
      new CustomEvent('message-status-socket-connect', {
        detail: { connectionCounter, timestamp: new Date().getTime() },
      })
    );
    if (window.socketStatus) {
      window.socketStatus[`message-status-${chatId}`] = {
        status: SocketStatus.CONNECTED,
        message: 'Connected successfully',
      };
    }
  });
  socket.on('disconnect', (reason) => {
    socket.off('message-status');
    socket.off('group-analytics');
    socket.off('link-analytics');
    document.dispatchEvent(
      new CustomEvent('message-status-socket-disconnect', {
        detail: { connectionCounter, timestamp: new Date().getTime() },
      })
    );
    if (window.socketStatus) {
      window.socketStatus[`message-status-${chatId}`] = {
        status: SocketStatus.NOT_CONNECTED,
        message: reason || 'Unknown reason',
      };
    }
  });
  return socket;
};
